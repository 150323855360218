import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Box, CircularProgress, Snackbar, TextField, Tooltip } from '@mui/material';
import { editUrgentforums } from '../../api/forum';
import { useMutation } from '@tanstack/react-query';
import CloseIcon from "@mui/icons-material/Close";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import axios from 'axios';
import { editLINKUrgenti2p } from '../../api/i2p';
import { BASE_URL } from '../../utils/config';

const I2PForumsTable = (props) => {
  const { selectedThread, handleSelectUrl, handleClosethread, globalState, notify, notifyAlert } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [pageLink, setPagelink] = useState(10)
  const [isMark, setIsMark] = useState(false)
  const [message, setMessage] = useState();
  const [rotation, setRotation] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isloading,setIsloding]=useState(false)
  const [isloading2,setIsloding2]=useState(false)
  // ----------------------------------------------------------------------------------------------------------
  console.log("current page===>",currentPage)
  const totalPages = selectedThread ? Math.ceil(parseInt(selectedThread[0].count_doc) / 10) : 0;
  // -------------------------------------------------------------------------------------------------------------
  const handleButtonClick = async (pageNumber) => {
    if (pageNumber <= totalPages) {
      if (pageNumber < currentPage) {
        setLoadingPrevious(true);

      } else {
        setLoadingNext(true);
      }
      
      setCurrentPage(pageNumber);
      

      await handleSelectUrl(globalState?.globalSite, globalState?.globalId, pageNumber);
      if (pageNumber < currentPage) {
        setLoadingPrevious(false);
        setPagelink(parseInt(pageLink) - 10)
      } else {
        setLoadingNext(false);
        setPagelink(parseInt(pageLink) + 10)
      }

    }
  };
  const handleButtonClick1 = async (pageNumber) => {
    if (pageNumber <= totalPages) {
      setCurrentPage(pageNumber);

      await handleSelectUrl(globalState?.globalSite, globalState?.globalId, pageNumber);
      setPagelink(parseInt(pageNumber) * 10)

    }
  };

  // -----------------------------------------------------------------------------------------------------------------
  const MarkallUrgentforums = async () => {
    setIsloding2(true)
    try {
      const response = await axios.post(`${BASE_URL}/i2p_markAllurgent`, {
        site: globalState?.globalSite,
        _isUrgent:!isMark
      });

      const results = response.data;
      // console.log(results)

      if (results.status === "success") {
        setIsMark(true);
        // notify(results?.msg)
      } else {
        notifyAlert(results?.msg)

      }
    } catch (error) {
      console.error('Error fetching channel info:=========>', error);
    }finally{
      setIsloding2(false)
      handleButtonClick1(currentPage)
      refreshForurgen()
    }

  }


  // -----------------------------------------------------------------------------------------------
  // const { mutate: deleteReddit2 } = useMutation(deleteReddit, {
  //   onSuccess: async (res) => {
  //     if (res.status === 200) {
  //       notify();
  //       refetch();
  //     }
  //   },
  // });

  const { mutate: editUrgenti2p } = useMutation(editLINKUrgenti2p, {
    onSuccess: (res) => {
      if (res.status === 200);
      handleButtonClick1(currentPage)

    },
  });
  // -------------------------------------------------------------------------------------------------
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  // -------------------------------------------------------------------------------------------------


  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const floatValue = parseFloat(inputValue);
  
    if (!isNaN(floatValue) && floatValue > 0 && floatValue <= totalPages) {
      setIsloding(true);
      try {
        await handleButtonClick1(floatValue);
      } catch (error) {
        console.error(error);
      } finally {
        setIsloding(false);
      }
    } else {
      notifyAlert("Please enter a valid number within the range");
    }
  };

  // console.log('Input value:', isloading,message);
  // ----------------------------------------------------------------------------------------------------
  const refreshForurgen = async () => {
    setRotation(rotation + 360);
    try {
      const response = await axios.post(`${BASE_URL}/i2p_refreshForurgent`, {
        site: globalState?.globalSite, // Replace with the actual site URL

      });

      if (response.data.status === 'success') {
        setIsMark(true);
        setMessage(response.data);
      } else {
        setIsMark(false);
        setMessage(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while fetching data.');
    } finally {
      setOpenSnackbar(true);

      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
    }
    setRotation(rotation + 360);
  };

  return (
    <div className="form_modal_scraping" style={{ "padding": "1rem 0" }}>
      <div
        className="form_modal_scraping_close_icon"
        style={{ padding: "0px" }}
        onClick={() => {
          handleClosethread();
        }}
      >
        <CloseIcon />
      </div>
      <h2 style={{ marginTop: "1px", padding: "0px" }}>PLEASE MARK THE SITE AS URGENT</h2>
      <Box sx={{
        overflow: "auto",
        width: "95%",
        maxWidth:'100%'

      }} >

        <TableContainer
          sx={{
            maxWidth:"100%",
            backgroundColor: "rgba(0,0,0,0.4)",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <Table stickyHeader aria-label="sticky table" style={{ flex: 1 }}>



            <TableHead >
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold',textAlign:'center' }}>
                  S.N
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold',textAlign:'center' }}>
                  Title
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold',textAlign:'center' }}>
                  Site Url
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold',textAlign:'center' }}>
                  Last Modified Date
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold',textAlign:'center' }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody  >
              {selectedThread.map((Site, index) => (
                <TableRow key={Site.id?.$oid}>
                  <TableCell style={{ maxWidth: '30vh', whiteSpace: 'nowrap',margin:"5px",textAlign:'center' }}>
                    {(currentPage - 1) * 10 + index + 1}
                  </TableCell>
                  <TableCell style={{ maxWidth: '30vh', overflow: 'hidden', textOverflow: 'ellipsis',textAlign:'center' }}>
                    {Site.title}
                  </TableCell>
                  <TableCell style={{ maxWidth: '70vh', overflow: 'hidden', textOverflow: 'ellipsis',textAlign:'center' }}> 
                    {Site.url}
                  </TableCell>
                  <TableCell style={{ maxWidth: '30vh', whiteSpace: 'nowrap',textAlign:'center' }}>
                    {Site?.lastModifiedDate}
                  </TableCell>
                  <TableCell style={{  maxWidth: '30vh',whiteSpace: 'nowrap',alignItems:'center' }}>
                    <Button
                      variant="contained"
                      color={Site?.isUrgent ? "success" : "primary"}
                      sx={{

                        fontSize: "1.5vh",
                        fontWeight: "600",
                        height: "fit-content",
                      }}
                      onClick={() => {
                        editUrgenti2p({
                          isUrgent: !Site?.isUrgent,
                          id: Site.id?.$oid,
                        })
                      }}
                    >
                      {Site?.isUrgent ? "Unmark" : "Mark"}
                    </Button>

                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <div style={{ width: "95%", display: 'flex', justifyContent: "space-between", margin: "8px" }}>
        <div style={{ display: 'flex' }}>
          {/* <Button
            variant="contained"
            color={isMark ? "success" : "primary"}
            style={{
              margin: "3px",
              fontSize: "1.5vh",
              fontWeight: "600",
              height: "45px",
            }}
            onClick={() => {
              MarkallUrgentforums()
            }}>
            {isMark ? `Unmark: ${selectedThread[0].count_doc} site` : `mark Urgent : ${message!== undefined && message?.nonUrgentDoc !== 0 ? message?.nonUrgentDoc : selectedThread[0].count_doc} site`}
          </Button> */}
          <div>
          <form onSubmit={handleSubmit}>
            <TextField
              label={`Page Number (0 to ${totalPages})`}
              id="standard-size-small"
              value={inputValue}
              onChange={handleChange}
              size="small"
              variant="standard"


              style={{
                margin: "3px",
                fontSize: "1.5vh",
                fontWeight: "600",
                height: "30px",


              }}
            />

            <Button type="submit"
              variant="contained"
              disabled={currentPage === totalPages || isloading}
              style={{
                margin: "3px",
                fontSize: "1.5vh",
                fontWeight: "600",
                height: "45px",
                
              }}
            >{isloading ? (
              <CircularProgress size="24px" color="primary" />
            ) : (
              "GO TO"
            )}</Button>
          </form>


        </div>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000} 
            // message={message?.status} 
          ><Alert severity={message?.status}>{message?.msg}</Alert>
          </Snackbar>


          <div
            style={{
              width: "50px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              transition: "transform 0.3s",
              transform: `rotate(${rotation}deg)`,
            }}
            onClick={() => {
              refreshForurgen(); 
            }}
          >
            <ReplayCircleFilledIcon />
          </div></div>
        <div>
          <Button
            variant="contained"
            style={{ margin: "3px", fontWeight: "600", height: "45px" }}
            disabled={currentPage === 1 || loadingPrevious}
            onClick={() => handleButtonClick(currentPage - 1)}
          >
            {loadingPrevious ? (
              <CircularProgress size="24px" color="primary" />
            ) : (
              "<<"
            )}
          </Button>
          {pageLink}
          <Button
            variant="contained"
            style={{ margin: "3px", fontWeight: "600", height: "45px" }}
            disabled={currentPage === totalPages || loadingNext}
            onClick={() => handleButtonClick(currentPage + 1)}
          >
            {loadingNext ? (
              <CircularProgress size="24px" color="primary" />
            ) : (
              ">>"
            )}
          </Button>
        </div>
        
      </div>
    </div>
  );
};

export default I2PForumsTable;
