import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function UserInfo(props) {
  const { handleClose, data } = props;
  const [copied, setCopied] = useState(false);

  const notify = () => toast("Copied!");
  console.log(data)
  return (
    <>
    
      <div className="form_modal_scraping">
      {copied && <ToastContainer />}
        <div
          className="form_modal_scraping_close_icon"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </div>
  
        <div className="login_form" style={{border:"10px",justifyContent:"center"}}>
          <div className="login_input_container" style={{ marginTop: "180px" ,backgroundColor:"#ebebf12e",padding:"50px"}}>
            <Typography>Name: <strong style={{ color: "green" }}>{data?.email}</strong> </Typography>
            <Typography>Password: <strong style={{ color: "green" }}>{data?.password}</strong></Typography>
            <Typography>Key: <strong style={{ color: "green" }}> {data?.secretKey}</strong> </Typography>
            {
              <div style={{width:"200px"}}>
                
                <CopyToClipboard text={data?.secretKey} onCopy={() => setCopied(true)}>
                  <Button variant="contained" color="success" onClick={()=>{notify()}}>Copy To Clipboard</Button>
                </CopyToClipboard>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
