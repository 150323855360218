import React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import "../../styles/status_styles.css"
import { BASE_URL_DISCORD } from '../../utils/config';
export const DiscordServiceStatus = (props) => {
    const { data1, isLoading1, refetch1 } = props
    console.log("------------Discord------------------", data1)
    const handleStart = async () => {
        const response = await fetch(`${BASE_URL_DISCORD}/start_discord_scheduler`, {
            method: 'POST',
        });
        const data = await response.json();
        refetch1()

    };

    const handleStop = async () => {
        const response = await fetch(`${BASE_URL_DISCORD}/stop_discord_scheduler`, {
            method: 'POST',
        });
        const data = await response.json();
        refetch1()

    };


    const statusData1 = (data1!== undefined && data1?.status !== "server down")? [data1] : [{

        "service_name": "please start server",
        "message": "please start server",
        "server_ip": "please start server",
        "server_port": "please start server",

    }];



    const schedulerStatus = isLoading1 ? 'Loading...' : data1?.message || 'Unknown';
    const startStopButtonText = schedulerStatus === 'Scheduler is running!' ? 'Stop' : 'Start';
    const startStopButtonColor = schedulerStatus === 'Scheduler is running!' ? 'success' : 'error';
    console.log(data1)
    return (
        <div >
            {/* <div className="form_modal_scraping"> */}

            <div style={{
                border: ( data1?.status === "server down") &&  data1.message !== "Scheduler is running!"  ? '1px solid red' : '1px solid green'
            }}
            >
                <div style={{ overflow: 'auto' }}>
                    <TableContainer component={Paper}>
                        <Table
                            style={{
                                borderCollapse: 'collapse',
                                width: '100%',
                                margin: '20px 0',
                                fontSize: '18px',
                                padding: '16px',
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        S.No.
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Service Name
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Status
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Server IP
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Server Port
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Current Scraping
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(statusData1.map((status, i) => (
                                    <TableRow key={status.service_name}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{status.service_name}</TableCell>
                                        <TableCell style={{color: data1 === undefined || data1?.message !== "Scheduler is running!" ? ' red' : '#32CD32'}}>{status.message}</TableCell>
                                        <TableCell>{status.server_ip}</TableCell>
                                        <TableCell>{status.server_port}</TableCell>
                                        <TableCell>{ status.scraping===null ? status.message : status.scraping }</TableCell>
                                        <TableCell>

                                            <Button
                                                color={startStopButtonColor}
                                                variant="contained"
                                                sx={{
                                                    width: '45%',
                                                    fontSize: '1.5vh',
                                                    fontWeight: '600',
                                                    height: 'fit-content',
                                                }}
                                                onClick={schedulerStatus === 'Scheduler is running!' ? handleStop : handleStart}
                                            >
                                                {startStopButtonText}
                                            </Button>

                                              
                                                <Button
                                                    color="error"
                                                    variant="contained"
                                                    sx={{
                                                        width: '45%',
                                                        fontSize: '1.5vh',
                                                        fontWeight: '600',
                                                        height: 'fit-content',
                                                        margin: "2px"
                                                    }}
                                                    onClick={() => {
                                                        refetch1(); 
                                                        console.log("ping");
                                                      }}
                                                >
                                                   {isLoading1 ? ( 
                                                <CircularProgress size="24px" color="success" />
                                            ) :"ping"}
                                                </Button>
                                            

                                        </TableCell>
                                    </TableRow>)))

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {/* </div> */}
        </div>

    );
};


