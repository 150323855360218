import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

import { Button, CircularProgress, Tooltip } from "@mui/material";

import { deleteCredentails } from "../../api/credentials";

export const CredentialsTable = ({ data, refetch, openForm, setEditData }) => {
  const notify = () => {
    toast.success("Deleted successfully");
  };
  const { mutate: deleteId } = useMutation(deleteCredentails, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify();
        refetch();
      }
    },
  });

  const handleDelete = (id) => {
    deleteId(id);
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="thread_container">
        <div className="table_header">
          <p>URL</p>
          <p>Name</p>
          <p>Status</p>
          <p>Actions</p>
        </div>
        {data === undefined && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="success" />
          </div>
        )}
        <div className="table_body">
          {data?.pages[0]?.list?.map((item) => (
            <div className="table_row" key={item?.id}>
              <Tooltip title={item?.site} placement="bottom-start">
                <div className={"table_columns"}>
                  <p>{item.site}</p>
                </div>
              </Tooltip>
              <div className={"table_columns"}>
                <p>{item?.loginId}</p>
              </div>
              <div className={"table_columns"}>
                <p>
                  {item?.password.slice(0, 3)}
                  ****
                  {item?.password.slice(7)}
                </p>
              </div>
              <div className={"table_columns"}>
                <div
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      height: "fit-content",
                      fontSize: "1.5vh",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      openForm();
                      setEditData(item);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{
                      height: "fit-content",

                      fontSize: "1.5vh",
                      fontWeight: "600",
                    }}
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
