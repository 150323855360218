import io from "socket.io-client";
import React, { useState, useEffect } from "react";
import { Typography, TableContainer, Box, Tab, Tabs, TableCell, Table, TableHead, TableRow, TableBody, Paper, Tooltip } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../utils/config";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, overflowY: "hidden" }}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader  >
                            <TableHead>
                                <TableRow>
                                    <TableCell>S.N</TableCell>
                                    <TableCell>Name or Url</TableCell>
                                    {/* <TableCell>Failed Count</TableCell> */}
                                    <TableCell>Time</TableCell>
                                    <TableCell>Reason</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {children}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AlertCenter() {
    const [dataList, setDataList] = useState([]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        axios.get(`${BASE_URL}/scheduler_error_info`)
            .then((response) => {
                setDataList(response.data)
                console.log('Data:', response.data[0]);
            })
            .catch((error) => {
                // Handle error
                console.error('Error:', error);
            });

    }, []);

    return (
        <div className="App" style={{ overflow: 'hidden' }}>
            <h2 className="head">Alert Center</h2>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Ransomware Alert" {...a11yProps(0)} />
                        <Tab label="Darkweb Forum Links Alert" {...a11yProps(1)} />
                        <Tab label="Darkweb Forum Data Alert" {...a11yProps(2)} />
                        <Tab label="i2p Forum Links Alert" {...a11yProps(3)} />
                        <Tab label="i2p Forum Data Alert" {...a11yProps(4)} />
                        <Tab label="Telegram Alert" {...a11yProps(5)} />
                        <Tab label="Discord Alert" {...a11yProps(6)} />
                        <Tab label="Reddit Alert" {...a11yProps(7)} />
                        <Tab label="Threads Alert" {...a11yProps(8)} />
                        <Tab label="Headful Alert" {...a11yProps(9)} />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0} style={{ maxHeight: '790px', overflowY: 'auto' }}>

                    {dataList
                        .filter(item => item.schedular === "Ransomware Schedular")
                        .map((item, index) => (

                            <TableRow key={index}>
                                {console.log(item?.time)}
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}


                </TabPanel>
                {/* forums */}
                <TabPanel value={value} index={1} style={{ maxHeight: '790px', overflowY: 'auto' }}>

                    {dataList
                        .filter(item => item.schedular === "Darkweb Forum Links Schedular")
                        .map((item, index) => (

                            <TableRow key={index}>
                                {console.log(item?.time)}
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}


                </TabPanel>

                <TabPanel value={value} index={2} style={{ maxHeight: '790px', overflowY: 'auto' }}>

                    {dataList
                        .filter(item => item.schedular === "Darkweb Forum Thread Scrape Schedular")
                        .map((item, index) => (

                            <TableRow key={index}>
                                {/* {console.log(item?.time)} */}
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}




                </TabPanel>
                {/* i2p */}
                <TabPanel value={value} index={3} style={{ maxHeight: '790px', overflowY: 'auto' }}>
                    {dataList
                        .filter(item => item.schedular === "i2p Forum Links Schedular")
                        .map((item, index) => (

                            <TableRow key={index}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                </TabPanel>
                <TabPanel value={value} index={4} style={{ maxHeight: '790px', overflowY: 'auto' }}>
                    {dataList
                        .filter(item => item.schedular === "i2p Forum Thread Scrape Schedula")
                        .map((item, index) => (

                            <TableRow key={index}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                </TabPanel>
                {/* TELEGRAM */}
                <TabPanel value={value} index={5} style={{ maxHeight: '790px', overflowY: 'auto' }}>
                    {dataList
                        .filter(item => item.schedular === "Telegram Schedular")
                        .map((item, index) => (

                            <TableRow key={index}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                <Tooltip title={item?.url} placement="bottom-start">
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                </TabPanel>
                {/* DIScord */}
                <TabPanel value={value} index={6} style={{ maxHeight: '790px', overflowY: 'auto' }}>
                    {dataList
                        .filter(item => item.schedular === "Discord Schedular")
                        .map((item, index) => (

                            <TableRow key={index}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                </TabPanel>
                {/* Reddit */}
                <TabPanel value={value} index={7} style={{ maxHeight: '790px', overflowY: 'auto' }}>
                    {dataList
                        .filter(item => item.schedular === "Reddit Scheduler")
                        .map((item, index) => (

                            <TableRow key={index}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                </TabPanel>
                {/* Thread */}
                <TabPanel value={value} index={8} style={{ maxHeight: '790px', overflowY: 'auto' }}>
                    {dataList
                        .filter(item => item.schedular === "Thread Scheduler")
                        .map((item, index) => (

                            <TableRow key={index}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                </TabPanel>
                {/*Headful */}
                <TabPanel value={value} index={9} style={{ maxHeight: '790px', overflowY: 'auto' }}>
                    {dataList
                        .filter(item => item.schedular === "Headful Scheduler")
                        .map((item, index) => (

                            <TableRow key={index}>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{index}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <Tooltip title={item?.url} placement="bottom-start">
                                    {item.failedCount === 0 ?
                                        <span style={{ color: "green" }}>{item.url}</span> :
                                        <span style={{ color: "red" }}>{item.url}</span>
                                    }
                                    </Tooltip>
                                </TableCell>
                                <TableCell style={{ whiteSpace: 'nowrap' }}><span style={{ color: "white" }}>{item?.time === undefined ? "no alert" : item?.time}</span></TableCell>
                                <TableCell style={{ maxWidth: '200px',  textOverflow: 'ellipsis' }}>
                                    <span style={{ color: "white" }}>{item?.reason}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                </TabPanel>
            </Box>
        </div>
    );
}
