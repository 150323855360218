import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import { toast } from "react-toastify";
import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import InformationBox from "./auth_info";
import { BASE_URL, BASE_URL_DISCORD } from "../../utils/config";
import "./styles.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchallAuthorization_keyData } from "../../api/discord";
// import Pagination from '@mui/material/Pagination';
// import Checkbox from '@mui/material/Checkbox';


export const Discord_auth_Form = (props) => {
    const { handleCloseAuth } = props;
    const [msg, setMsg] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    // const [data, setData] = useState([]);
    const [auth, setAuth] = useState({
        Authorization_key: '',

    });
    const notify = (type) => {
        toast.success(`${type} successfull`);
    };

    const handleChange = (event) => {
        setShowWarning(false);
        const { name, value } = event.target;
        setAuth((prevAuth) => ({
            ...prevAuth,
            [name]: value,
        }));
    };

    const handle_Submit = async (event) => {
        event.preventDefault();
        if (
            auth.Authorization_key.trim() === ''
        ) {
            setShowWarning(true);
        } else {
            try {

                const res = await axios.post(`${BASE_URL_DISCORD}/add_Authorization_key`, {
                    Authorization_key: auth.Authorization_key,

                });
                const results = res.data;
                setMsg(results);
                console.log(results)
                setTimeout(() => { setMsg(null)}, 3000);

            } catch (error) {
                console.error('Error fetching channel info:', error);
                setMsg({ messages: "Network Error" });
                setTimeout(() => { setMsg(null)}, 3000);

            }
            // setShowWarning(false);

        };
    }



    const handleHover = () => {
        setShowInfo(true);
    };

    const handleLeave = () => {
        console.log("clicked")
        setShowInfo(false);
    };
    // --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

   

    const fetchallAuthorization_keyData2 = async () => {
        try {
            const res = await fetch(`${BASE_URL_DISCORD}/allAuthorization_key`, {
                method: "GET",
            });
            return res.json();
        } catch (error) {
            console.error('Error fetching data:', error);
            return []; 
        }
    };
    const { data, isLoading, refetch } = useQuery(['authorizationKeyData'], fetchallAuthorization_keyData2);
    // if (isLoading) return 'Loading...'



   
    const deleteAuthorizationKey = async (id) => {
        try {
          const response = await fetch(`${BASE_URL_DISCORD}/info_Authorization_key/${id}`, {
            method: 'DELETE',
          });
    
          if (!response.ok) {
            throw new Error('Delete request failed');
          }
          refetch();
        } catch (error) {
          console.error('Error deleting data:', error);
        }
      };
    
  
    return (
        <>
            <div className="form_modal_scraping">
                <div
                    className="form_modal_scraping_close_icon"
                    onClick={() => {
                        handleCloseAuth();
                        // setEditData("");
                    }}
                >
                    <CloseIcon />
                </div>
                <h1>Authorization key</h1>
                {showWarning && (<Alert severity="warning">Please fill out the entire form before adding!</Alert>)}
                {msg !== null && (
                    <Alert severity={(msg.status === 'Success') ? 'success' : "error"}>{msg.messages}</Alert>
                )}
                <div className="scraping_input_container">
                    <div style={{ display: 'flex' }}>
                        <TextField
                            required
                            type="text"
                            name="Authorization_key"
                            value={auth.Authorization_key}
                            placeholder="Add Authentication Key"
                            className="scraping_input_site"
                            onChange={handleChange}
                        />
                        <InfoIcon
                            style={{ margin: "15px", justifyContent: "center" }}
                            onClick={handleHover}
                        
                        >
                            Hover Me
                        </InfoIcon>

                        {showInfo && <InformationBox handleLeave={handleLeave} />}
                    </div>
                    <Button variant="contained" color="success" onClick={handle_Submit}>
                        Add (Discord Authorization)
                    </Button>
                </div>
                <div style={{margin:"15px"}}>
                    <div style={{ border: '1px solid #dddddd' ,borderRadius:"5px"}}>
                        <div style={{ overflow: 'auto' }}>
                            <TableContainer component={Paper}>
                                <Table
                                    style={{
                                        borderCollapse: 'collapse',
                                        width: '100%',
                                        maxWidth: '800px',
                                        margin: '20px 0',
                                        fontSize: '18px',
                                        padding: '16px',
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold' }}>S.No.</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>auth key</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Add Time</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>error</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>is working</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data!==undefined && data.map((item, i) => (
                                            <TableRow key={item?._id?.$oid}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>{item?._id?.$oid}</TableCell>
                                                <TableCell>{item?.auth_key}</TableCell>
                                                <TableCell>{item?.time}</TableCell>
                                                <TableCell>{item?.status}</TableCell>
                                                <TableCell>{item?.error}</TableCell>
                                                <TableCell>{item?.is_working ? 'Yes' : 'No'}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        color="error"
                                                        variant="contained"
                                                        sx={{
                                                            width: '25%',
                                                            padding: '8px',
                                                            fontSize: '1.5vh',
                                                            fontWeight: '600',
                                                            height: 'fit-content',
                                                        }}
                                                        onClick={() => deleteAuthorizationKey(item?._id?.$oid)}
                                                    >
                                                        Delete
                                                    </Button>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {data==undefined && <h2 style={{margin:"15px"}}>Loading...</h2>}
                        <div className="scraping_input_container">
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{
                                    width: '25%',
                                    padding: '8px',
                                    fontSize: '1.5vh',
                                    fontWeight: '600',
                                    height: 'fit-content',
                                }}
                                onClick={() => { refetch(); }}
                            >
                                refresh
                            </Button>
                        </div>
                    </div>



                    {/* Pagination */}
                    {/* <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        sx={{ margin: '20px auto', display: 'flex', justifyContent: 'center' }}
                    /> */}
                </div>

            </div>
        </>
    );
};
