import { BASE_URL_THREADS } from "../utils/config";


export const getThreads = async () => {
  const res = await fetch(`${BASE_URL_THREADS}/threads_allProfile`, {
    method: "GET",
  });
  return res.json();
};

export const deleteThreads = async (id) => {
  return fetch(`${BASE_URL_THREADS}/threads_profile/${id}`, {
    method: "Delete",
  });
};

export const editUrgentThreads = async (data) => {
  return fetch(`${BASE_URL_THREADS}/threads_profile/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isUrgent: data?.isUrgent }),
  });
};

export const Status_Threads= async () => {
  const response = await fetch(`${BASE_URL_THREADS}/check_threads_scheduler`,{
    method: "GET",
});
  return response.json()
};