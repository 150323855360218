import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Telegram_Form } from "../telegram/form";
import { TelegramTable } from "../telegram/table";
import "../../components/telegram/styles.css";
import { getTelegram } from "../../api/telegram";
import { Telegram_session_Form } from "../telegram/session_form";


export default function ScrapingEngineTelegram() {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [editData, setEditData] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [TotalCount, setTotalCount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["credentails"],
    queryFn: () => getTelegram(),
  });

  useEffect(() => {
    setTotalCount(Math.ceil(JSON.parse(data || "[]").length / rowsPerPage));
  }, [data])

  return (
    <div className="login_container">
       <TelegramIcon sx={{ fontSize: 40 }}/>
      <div className="AddButton" style={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          onClick={handleClickOpen2}
          sx={{
            width: "25vh",
            height: "5vh",
            fontSize: "2vh",
            fontWeight: "600",
            backgroundColor: "#FC3B93",
          }}
        >
          Add Session file
        </Button>

        {/* New button on the right side */}
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            width: "25vh",
            height: "5vh",
            fontSize: "2vh",
            fontWeight: "600",
            backgroundColor: "#FC3B93",
          }}
        >
          Add Url
        </Button>
      </div>



      {
        open && (
          <Telegram_Form
            refetch={refetch}
            handleClose={handleClose}
            data_to_edit={editData}
            setEditData={setEditData}
          />
        )
      }
      {
        open2 && (
          <Telegram_session_Form
            refetch={refetch}
            handleClose2={handleClose2}
            data_to_edit={editData}
            setEditData={setEditData}
          />
        )
      }

      <TelegramTable
        data={
          data !== undefined
            ? JSON.parse(data).slice(
              page * rowsPerPage,
              rowsPerPage + page * rowsPerPage
            )
            : []
        }
        refetch={refetch}
        setEditData={setEditData}
        openForm={handleClickOpen}
        page={page}
        perPage={rowsPerPage}
      />

      <div>
        {/* <TablePagination
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={TotalCount}
        /> */}
        <Pagination
          page={page + 1}
          size="large"
          count={TotalCount}
          color="secondary"
          variant='outline'
          shape='rounded'
          onChange={(event, value) => setPage(value - 1)}
        />
      </div>
    </div >
  );
}
