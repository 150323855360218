import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import "../../components/discord/styles.css";
import { DiscordTable } from '../discord/table';
import Discord_form from '../discord/form';
import { Discord_auth_Form } from '../discord/auth_key';
import Pagination from "@mui/material/Pagination";
import { useQuery } from '@tanstack/react-query';
import { getDiscord } from '../../api/discord';
import FaceIcon from '@mui/icons-material/Face'

const ScrapingEngineDiscord = () => {
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [TotalCount, setTotalCount] = useState(0);



    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseAuth = () => {
        setOpen2(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const { data, refetch, isLoading } = useQuery({
        queryKey: ["credentails"],
        queryFn: () => getDiscord(),
    });
    // console.log(data)

    useEffect(() => {
        setTotalCount(Math.ceil(JSON.parse(data || "[]").length / rowsPerPage));
    }, [data])

    return (
        <div className="login_container">
            <FaceIcon sx={{ fontSize: 40 }} />

            <div className="AddButton" style={{ justifyContent: "space-between" }}>

                <Button
                    variant="contained"
                    onClick={handleClickOpen2}
                    sx={{
                        width: "25vh",
                        height: "5vh",
                        fontSize: "2vh",
                        fontWeight: "600",
                        backgroundColor: "#FC3B93",
                    }}
                >
                    Add auth key
                </Button>
                <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    sx={{
                        width: "25vh",
                        height: "5vh",
                        fontSize: "2vh",
                        fontWeight: "600",
                        backgroundColor: "#FC3B93",
                    }}
                >
                    Add Channel
                </Button>
            </div>
            {open &&
                <Discord_form
                    handleClose={handleClose}
                />}
            {open2 &&
                <Discord_auth_Form
                    handleCloseAuth={handleCloseAuth}
                />}


            <DiscordTable
                data={

                    data !== undefined
                        ? JSON.parse(data).slice(
                            page * rowsPerPage,
                            rowsPerPage + page * rowsPerPage
                        )
                        : []
                }
                refetch={refetch}
                page={page}
                perPage={rowsPerPage}
            />
            <div>
                {/* <TablePagination
            page={page}
            component="div"
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 25, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={TotalCount}
            /> */}
                <Pagination
                    page={page + 1}
                    size="large"
                    count={TotalCount}
                    color="secondary"
                    variant='outline'
                    shape='rounded'
                    onChange={(event, value) => setPage(value - 1)}
                />
            </div>

        </div>
    );
};

export default ScrapingEngineDiscord;
