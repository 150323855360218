import "./index.css";
import Login from "./components/UI/Login";
import NavBar from "./components/navbar/navbar";
import Dashboard from "./components/UI/Dashboard";
import ListTable from "./components/UI/ListTable";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrapingEngine from "./components/UI/ScrapingEngine";
import ScrapingEngineLogs from "./components/UI/ScrapingEngineLogs";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrapingEngineDarkWeb from "./components/UI/ScrapingEngineDarkWeb";
import ScrapingEngineTelegram from "./components/UI/ScrapingEngineTelegram";
import ScrapingEngineThread from "./components/UI/ScrapingEngineThread"
import ScrapingEngineDiscord from "./components/UI/discord";
import LinkValidation from "./components/UI/LinkValidation";
import ScrapingEngineReddit from "./components/UI/ScrapingEngineReddit";
import Services_status from "./components/UI/services_status";
import AddUser from "./components/AddUser/AddUser";
import I2P from "./components/UI/I2P";
import HeadfulBrowser from "./components/UI/HeadfulBrowser";
import Headful from './components/Headful/Headful'
import AlertCenter from "./components/UI/alert_center";
import Stealer_log_Headful from "./components/UI/Stealer_log";

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        light: "#757ce8",
        main: "#3f50b5",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });
  const location = useLocation();

  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline>
            {location.pathname !== "/" && <NavBar />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/addUser" element={<AddUser />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Scraping_Engine" element={<ScrapingEngine />} />
              {/* <Route
                path="/Scraping_Engine_Forums"
                element={<ScrapingEngineDarkWeb />}
              /> */}
              <Route path="/Credential_manager" element={<ListTable />} />
              <Route path="/link_validation" element={<LinkValidation />} />
              <Route
                path="/Scraping_Engine_Logs"
                element={<ScrapingEngineLogs />}
              />
              <Route
                path="/Alert_center"
                element={<AlertCenter />}
              />
              <Route
                path="Scraping_Engine_Telegram"
                element={<ScrapingEngineTelegram />}
              />
              <Route
                path="/Scraping_Engine_Discord"
                element={<ScrapingEngineDiscord/>}
              />
              <Route
                path="/Scraping_Engine_Reddit"
                element={<ScrapingEngineReddit/>}
              />
              <Route
                path="/Scraping_Engine_Thread"
                element={<ScrapingEngineThread/>}
              />
              <Route
                path="/Services_status"
                element={<Services_status/>}
              />
              <Route
                path="/i2p"
                element={<I2P/>}
              />
               <Route
                path="/headfulBrowser"
                element={<HeadfulBrowser/>}
              />
              <Route
                path='/headful'
                element={<Headful/>}
              />
              <Route
                path='/Stealer_log_headful'
                element={<Stealer_log_Headful/>}
              />
            </Routes>
            <ToastContainer
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </CssBaseline>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;