import { BASE_URL,BASE_URL_TELEGRAM } from "../utils/config";

export const postTelegram = async (data) => {
  return fetch(`${BASE_URL}/TelegramChannel `, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url: data }),
  });
};
export const getTelegram = async () => {
  const res = await fetch(`${BASE_URL}/allChannel`, {
    method: "GET",
  });
  return res.json();
};

export const deleteTelegram = async (id) => {
  return fetch(`${BASE_URL}/TelegramChannel/${id}`, {
    method: "Delete",
  });
};

export const editUrgentTelegram = async (data) => {
  return fetch(`${BASE_URL}/TelegramChannel/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isUrgent: data?.isUrgent }),
  });
};

export const Status_Telegram = async () => {
  const response = await fetch(`${BASE_URL_TELEGRAM}/check_telegram_scheduler`,{
    method: "GET",
});
  return response.json()
};
