import axios from "axios";

import Logo from "../../assets/logo.png";
import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "../../styles/login.css";

export default function Login() {
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Token, setToken] = useState("");
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleChangeToken = (e) => {
    setToken(e.target.value);
  };
  async function handleSubmit() {
    var object = { email: Email, password: Password, OTP: Token };
    console.log(object);
    const response = await axios.post("https://mtapi.emsec.dev/login", object);
    if (response.data === "Login Successfull") {
      sessionStorage.setItem("loggedin", "true");
      navigate("/Dashboard", { replace: false });
    } else {
      alert("login failed!");
      navigate("/", { replace: false });
    }
  }

  return (
    <div className="login_container">
      <div className="login_form">
        <div className="login_input_container">
          <div className="login_logo">
            <img src={Logo} />
            <p>Mariana Trench - Validation Portal</p>
          </div>
          <TextField
            type="text"
            className="input"
            id="Username"
            value={Email}
            name="Username"
            label="Username"
            variant="outlined"
            onChange={handleChangeEmail}
            placeholder="Email or Username&#42;"
          />
          <TextField
            type="password"
            className="input"
            id="password"
            name="Password"
            value={Password}
            label="Password"
            variant="outlined"
            onChange={handleChangePassword}
            placeholder="Password&#42;"
          />
          <TextField
            type="Token"
            className="input"
            id="Token"
            name="Token"
            value={Token}
            label="Token"
            variant="outlined"
            onChange={handleChangeToken}
            placeholder="Token&#42;"
          />

          <div className="buttondiv">
            <button className="submitbtn1" onClick={handleSubmit}>
              <b>Sign In</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
