import { BASE_URL } from "../utils/config";

export const posti2p = async (data) => {
  return fetch(`${BASE_URL}/i2p`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data }),
  });
};

export const geti2p = async () => {
  const res = await fetch(`${BASE_URL}/i2p`, {
    method: "GET",
  });
  return res.json();
};

export const deletei2p = async (id) => {
  return fetch(`${BASE_URL}/i2p/${id}`, {
    method: "Delete",
  });
};

export const puti2p = async (data) => {
  return fetch(`${BASE_URL}/i2p/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data }),
  });
};

export const editLINKUrgenti2p = async (data) => {
  return fetch(`${BASE_URL}/i2p_markurgentLink/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isUrgent: data?.isUrgent }),
  });
};



