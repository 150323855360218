import { BASE_URL,BASE_URL_DISCORD } from "../utils/config";

// export const postDiscord = async (data) => {
//   return fetch(`${BASE_URL}/discord_allChannel `, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ url: data }),
//   });
// };
export const getDiscord = async () => {
  const res = await fetch(`${BASE_URL}/discord_allChannel`, {
    method: "GET",
  });
  return res.json();
};

export const deleteDiscord = async (id) => {
  return fetch(`${BASE_URL}/discord_Channel/${id}`, {
    method: "Delete",
  });
};

export const editUrgentDiscord = async (data) => {
  return fetch(`${BASE_URL}/discord_Channel/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isUrgent: data?.isUrgent }),
  });
};
export const fetchallAuthorization_keyData = async () => {
  const res = await fetch(`${BASE_URL_DISCORD}/allAuthorization_key`, {
      method: "GET",
  });
  return res.json();
};

export const Status_Discord = async () => {
  const response = await fetch(`${BASE_URL_DISCORD}/check_discord_scheduler`,{
    method: "GET",
});
  return response.json()
};
