import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { postCredentails, putCredentails } from "../../api/credentials";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

const initialFormData = {
  site: "",
  loginId: "",
  password: "",
};

export const Credentials_Form = (props) => {
  const { handleClose, data_to_edit, setEditData, refetch } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const notify = (type) => {
    toast.success(`${type} successfull`);
  };

  const { mutate, isLoading } = useMutation(postCredentails, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify("Uploaded");
        handleClose();
        refetch();
        setFormData(initialFormData);
      }
    },
  });

  const { mutate: edit, isLoading: editing } = useMutation(putCredentails, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        refetch();
        handleClose();
        setEditData("");
        notify("Edited");
        setFormData(initialFormData);
      }
    },
  });

  const handleSubmit = () => {
    if (data_to_edit?.id) {
      edit({ ...formData, id: data_to_edit.id });
      return;
    }
    mutate(formData);
  };
  useEffect(() => {
    if (data_to_edit.id) {
      setFormData(data_to_edit);
    }
  }, [data_to_edit]);

  return (
    <>
      <div className="form_modal_scraping">
        <div
          className="form_modal_scraping_close_icon"
          onClick={() => {
            handleClose();
            setEditData("");
          }}
        >
          <CloseIcon />
        </div>
        <div className="scraping_input_container">
          <TextField
            required
            type="text"
            label="Login ID"
            variant="standard"
            value={formData.loginId}
            placeholder="Login ID&#42;"
            className="scraping_input"
            onChange={(e) => handleChange(e, "loginId")}
          />

          <TextField
            required
            label="Password"
            variant="standard"
            className="scraping_input"
            placeholder="Password&#42;"
            value={formData.password}
            type={showPassword ? "text" : "password"}
            onChange={(e) => handleChange(e, "password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff style={{ fontSize: "4vh" }} />
                    ) : (
                      <Visibility style={{ fontSize: "4vh" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="scraping_input_container">
          <TextField
            required
            label="Site"
            type="text"
            variant="standard"
            placeholder="Site&#42;"
            value={formData.site}
            className="scraping_input_site"
            onChange={(e) => handleChange(e, "site")}
          />
        </div>
        <div className="scraping_button_container">
          <Button onClick={handleClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
