import {  TextField } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import "../../styles/login.css";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { toast } from "react-toastify";
import UserInfo from "./UserInfo";
export default function AddUser() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const notify_success = (type) => {
    toast.success(`${type}`);
    };
  const notify_fail = (type) => {
    toast.warning(`${type}`);
    };


  const handleClose = () => {
    setOpen(false);
};
  // const handleSubmit = async () => {
  //   const obj = { email: email, password: password };
  //   const res = await axios.post("http://127.0.0.1:5000/addUser", obj);
  //   if (res.data.status === "save successfull") {
      
     
  //     setOpen(true);
  //     setData(res.data)
      
  //     setEmail("");
  //     setPassword("");
  //   } else if (res.data === "User already exist") {
  //     alert(res.data);
  //     setEmail("");
  //     setPassword("");
  //   } else {
  //     alert("Failed! Write correct token");
  //     setEmail("");
  //     setPassword("");
  //   }
  // };
  

	
    




    const handleSubmit = async () => {
    const obj = { email: email, password: password};
    const res = await axios.post("https://mtapi.emsec.dev/add_User", obj);
    if (res.data.status === "save successfull") {
     

      notify_success("User added Successfully");
      setOpen(true);
      setData(res.data)
      setEmail("");
      setPassword("");
      
    } else if (res.data.status === "User already exist") {
      notify_fail(res.data);
    } else {
      notify_fail("Failed! Write correct token")
    }
  };
  return (
    <>
    {open && <UserInfo handleClose={handleClose} data={data}/>}
     
      <div className="login_container">
        <div className="login_form">
          <div className="login_input_container">
            <div className="login_logo">
              <PersonAddAltIcon sx={{ fontSize: 40 }} />
              <p>Add User</p>
            </div>
            
            <div className="scraping_input_container">
              
              <TextField
                type="email"
                variant="outlined"
                placeholder="username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                type="password"
                variant="outlined"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="buttondiv">
                <button className="submitbtn1" onClick={handleSubmit}>
                  Add User
                </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
