import React from 'react';
import Box from '@mui/material/Box';

const InformationBox = ({handleLeave}) => {
  return (
    
      <div className="form_modal_scraping" onClick={handleLeave}>
      <Box
      sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          fontSize: '14px',
          color: 'white',
          zIndex: 1, 
        }}
      >
        <p>An authorization key (bot token) in Discord is a unique identifier for your bot. To get it, create a bot on the Discord Developer Portal. Generate the token on the "Bot" tab. Keep the token private. Add the bot to a server using the OAuth2 URL Generator. Use the token to authenticate the bot with the Discord API.</p>
      </Box>
      
    </div>
    );
  };
export default InformationBox;
