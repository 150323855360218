import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import {Button,TextField,} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MyTable from './info_table';
import { BASE_URL_DISCORD } from '../../utils/config';
import "../../components/discord/styles.css";

const Discord_form = (props) => {
    const{handleClose}=props;
    const [invitationLink, setInvitationLink] = useState("");
    const [msg, setMsg] = useState(null);
    const [selectedChannel , setSelectedChannel] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    

    

    const handleGetChannelInfo = async (event) => {
        event.preventDefault();
        if (
            invitationLink.trim() === ''
        ) {
            setMsg({status:"error",messages:"Please fill the box before getting the channel info."})
            setTimeout(() => {
                setMsg(null);
            }, 5000);
        } else {
        try {
            const response = await axios.post(`${BASE_URL_DISCORD}/channel_info`, {
                invitation_link: invitationLink,
                server_id: null,
                channel_id: null,
            });
            const results = response.data;
            // console.log(status, output)
            if (results.status !== "error" && results.status !== "server down"){
                setSelectedChannel(results.output);
                setMsg(results)
                setTimeout(() => {
                    setMsg(null);
                    
                }, 5000);
            } else {
                setMsg(results)
                setTimeout(() => {
                    setMsg(null);
                    
                }, 5000);

            }
        } catch (error) {
            console.error('Error fetching channel info:', error);
            setMsg({status:"error",messages:"Network Error"})
            setTimeout(() => {
                setMsg(null);
            }, 5000);
        }
    }};

    const handleGetSelectedChannelInfo = async () => {
        try {
            console.log(selectedIds)
            const response = await axios.post(`${BASE_URL_DISCORD}/selected_channel_info`, {
                channel_id_list: selectedIds,
            });
            const results = response.data;
           
            console.log(results.status)
            setSelectedChannel(null)
            setMsg(results)
            
            setTimeout(() => {
                setMsg(null);
                // handleClose()
                setSelectedChannel(null)
            }, 5000);
            

                ;
        } catch (error) {
            console.error('Error fetching selected channel info:', error);
            setSelectedChannel(null)
            setMsg({status:"error",messages:"Network Error"})
        }
    };

    const handleSelectAll = () => {
        if (!selectAll) {
          const allChannelIds = selectedChannel.map((channel) => channel.channel_id);
          setSelectedIds(allChannelIds);
        } else {
          setSelectedIds([]);
        }
        setSelectAll((prevSelectAll) => !prevSelectAll);
      };

    
    const handleSelectId = (channelId) => {
        setSelectedIds((prevIds) => {
            if (prevIds.includes(channelId)) {
                return prevIds.filter((id) => id !== channelId);
            } else {
                return [...prevIds, channelId];
            }
        });
    };

    const tableHeaderCellStyle = {
        color: "white",
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px',
    };

    const tableCellStyle = {
        background: 'transparent',
        color: "white",
        textAlign: 'left',
        padding: '8px',
    };
    return (
        <div className="form_modal_scraping">
            <div
                className="form_modal_scraping_close_icon"
                onClick={() => {
                    handleClose();
                    
                }}
            >
                <CloseIcon />
            </div>

            <h1>Get Discord Channel Info</h1>
            
            <div className="scraping_input_container">
                {msg !== null && (<Alert severity={(msg.status === 'Success') ? 'success' : "error"}>{msg.messages}</Alert>)}
                <TextField
                    type="text"
                    placeholder="Invitation Link"
                    value={invitationLink}
                    onChange={(e) => setInvitationLink(e.target.value)}
                />
                <Button color="primary"
                    variant="contained"
                    sx={{
                        width: "45%",
                        fontSize: "1.5vh",
                        fontWeight: "600",
                        height: "fit-content",
                    }}
                    onClick={handleGetChannelInfo}>Get Channel Info
                </Button>
            </div>


            {selectedChannel !== null && (<MyTable 
             selectedChannel={selectedChannel}
             selectAll={selectAll}
             handleClose={handleClose}
             selectedIds={selectedIds}
             handleSelectId={handleSelectId}
             handleSelectAll={handleSelectAll}
             handleGetSelectedChannelInfo={handleGetSelectedChannelInfo}
             
            />
          
            )}

        

        </div>
    );
};

export default Discord_form;
