import React, { useState, useEffect } from "react";
import ForumIcon from "@mui/icons-material/Forum";
import { Button, TablePagination, Pagination } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { geti2p } from "../../api/i2p";
import {I2PForm} from "../I2P/I2PForm";
import {I2PTable} from "../I2P/I2PTable"
import "../../styles/login.css";
function I2P() {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [TotalCount, setTotalCount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["credentails"],
    queryFn: () => geti2p(),
  });

  useEffect(() => {
    setTotalCount(Math.ceil(JSON.parse(data || "[]").length / rowsPerPage));
  }, [data])

  return (
    <>
      <div className="login_container">
      <ForumIcon sx={{ fontSize: 40 }}/>
        <div className="AddButton">
          <Button
            variant="contained"
            sx={{
              width: "18vh",
              height: "5vh",
              fontSize: "2vh",
              fontWeight: "600",
              backgroundColor: "#FC3B93",
            }}
            onClick={handleClickOpen}
          >
            Add i2p
          </Button>
          {open && (
            <>
              <I2PForm
                refetch={refetch}
                setOpen={setOpen}
                editData={editData}
                setEditData={setEditData}
              />
            </>
          )}
        </div>
        {!isLoading && (
          <I2PTable
            refetch={refetch}
            data={
              data !== undefined
                ? JSON.parse(data).slice(
                    page * rowsPerPage,
                    rowsPerPage + page * rowsPerPage
                  )
                : []
            }
            setEditData={setEditData}
            openForm={handleClickOpen}
            page={page}
            perPage={rowsPerPage}
          />
        )}
        <div>
          <Pagination
            page={page+1}
            size="large"
            count={TotalCount}
            color="secondary"
            variant='outline'
            shape='rounded'
            onChange={(event, value) => setPage(value - 1)}
          />
        </div>
      </div>
    </>
  );
}

export default I2P;
