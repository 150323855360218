import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useNavigate } from "react-router-dom";
import {
    Pagination,
   
} from "@mui/material";

export default function Stealer_log_Headful() {
    const navigate = useNavigate();
    const statusNotify = () => toast.warning("Busy!");
   

    const [page, setPage] = useState(0);
    

   

    const handleHeadful = async (e) => {
        const headfulData = {
            name: e?.name,
            url: e?.url,
            start:true
        };
        console.log(headfulData)
        try {
            const firstApiResponse = await axios.get(
                "https://headapi.emsec.dev/status"
            );
            console.log(firstApiResponse.data, "1");
            if (firstApiResponse.data.status === "busy") {
                statusNotify();
            }
            if (firstApiResponse.data.status === "free") {
                const secondApiResponse = await axios.post(
                    "https://headapi.emsec.dev/stealerLogs",
                    headfulData
                );
                navigate("/headful", { replace: true });
                console.log("Response from the second API:", secondApiResponse);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const handleTest = async (e) => {
        const headfulData = {
            name: e?.name,
            url: e?.url,
            start:true
        };
        try {
            const firstApiResponse = await axios.get(
                "https://headapi.emsec.dev/status"
            );
            console.log(firstApiResponse.data, "1");
            if (firstApiResponse.data.status === "busy") {
                statusNotify();
            }

            if (firstApiResponse.data.status === "free") {
                const secondApiResponse = await axios.post(
                    "https://headapi.emsec.dev/stealerLogs",
                    headfulData
                );
                navigate("/headful", { replace: true });

                console.log("Response from the second API:", secondApiResponse);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    
   
    const dummyData = [
        { url: 'http://rumarkstror5mvgzzodqizofkji3fna7lndfylmzeisj5tamqnwnr4ad.onion/', name: 'Russian Market' },
        
        
    ];
    return (
        <div className="login_container">
            <PersonSearchIcon sx={{ fontSize: "60px", padding: "5px" }} />
            <div>
                <Paper sx={{ width: "99%", overflow: "hidden", margin: 0 }}>
                    <TableContainer sx={{ width: "95vw", height: "79vh", backgroundColor: "rgba(0,0,0,0.4)", margin: 0 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>S.N</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>URL</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Name</TableCell>
                                    <TableCell style={{ textAlign: 'center', fontWeight: 'bold' }}>Button</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dummyData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.url}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{row.name}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                sx={{
                                                    height: "fit-content",
                                                    fontSize: "1.5vh",
                                                    fontWeight: "600",
                                                }}
                                                onClick={() => handleHeadful(row)}
                                            >
                                                HeadFul Scraping
                                            </Button>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Pagination
                    page={page + 1}
                    size="large"
                    count={Math.ceil(dummyData.length / 10)}
                    color="secondary"
                    variant="outline"
                    shape="rounded"
                    onChange={(event, value) => setPage(value - 1)}
                />
            </div>
        </div>
    );
}
