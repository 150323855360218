import io from "socket.io-client";
import React, { useState, useEffect } from "react";
import { Typography, Box, Tab, Tabs } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ScrapingEngineLogs() {
  const [dataList, setDataList] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const newSocket = io(`https://logsmt.emsec.dev`);
    // setSocket(newSocket);
    newSocket.on("connect", () => {
      // setIsConnected(true);
      console.log("Connected");
    });

    newSocket.on("data", (data) => {
      // console.log("Data", data);
      // console.log("Data", data?.engine?.toLowerCase());
      setDataList((oldArr) => [
        { engine: data?.engine?.toLowerCase(), type: "data", data: JSON.stringify(data.data, null, 4), date: new Date().toISOString() },
        ...oldArr
      ]);
      console.log(dataList)
    });

    newSocket.on("log", (log) => {
      // console.log("LOG", log);
      setDataList((oldArr) => [{ engine: log?.engine?.toLowerCase(), type: "log", data: log.msg, date: new Date().toISOString() }, ...oldArr]);
   console.log(dataList)
    });

    return () => {
      newSocket.close();
      newSocket.off("data");
      newSocket.off("log");
      // socket.off('pong');
    };
  }, []);

  return (
    <div className="App" style={{ overflow:"hidden" }}>
      <h2 className="head">Scraping Engine Logs</h2>
      {/* {dataList.map((item, index) => (
        <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
          {item.type === "log" && <p style={{ color: "red" }}>{item.data}</p>}
          {item.type === "data" && (
            <p style={{ color: "white" }}>{item.data}</p>
          )}
        </div>
      ))} */}

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable" 
            scrollButtons="auto" 
          >
            <Tab label="Ransomware Logs" {...a11yProps(0)} />
            <Tab label="Darkweb Forum Links Logs" {...a11yProps(1)} />
            <Tab label="Darkweb Forum Data Logs" {...a11yProps(2)} />
            <Tab label="i2p Forum Links Logs" {...a11yProps(3)} />
            <Tab label="i2p Forum Data Logs" {...a11yProps(4)} />
            <Tab label="Telegram Logs" {...a11yProps(5)} />
            <Tab label="Discord Logs" {...a11yProps(6)} />
            <Tab label="Reddit Logs" {...a11yProps(7)} />
            <Tab label="Threads Logs" {...a11yProps(8)} />
            <Tab label="Headful Logs" {...a11yProps(9)} />
            <Tab label="NLP Logs" {...a11yProps(10)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}  style={{ maxHeight: '750px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "ransomware")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/* forums */}
        <TabPanel value={value} index={1} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "darkweb_forum_thread_links")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        <TabPanel value={value} index={2} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "darkweb_forum_thread_data")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/* i2p */}
        <TabPanel value={value} index={3} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "i2p_forum_thread_links")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        <TabPanel value={value} index={4} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "i2p_forum_thread_data")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.engine === "i2p_forum_thread_data"&&console.log(item.data)}
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/* TELEGRAM */}
        <TabPanel value={value} index={5} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "telegram")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/* DIScord */}
        <TabPanel value={value} index={6} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "discord")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/* Reddit */}
        <TabPanel value={value} index={7} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "reddit")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/* Thread */}
        <TabPanel value={value} index={8} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "thread")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/*Headful */}
        <TabPanel value={value} index={9} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "headful_forum")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
        {/*NLP_ENGINE*/}
        <TabPanel value={value} index={10} style={{ maxHeight: '780px', overflowY: 'auto' }}>
          {dataList
            .filter((item) => item.engine === "nlp_engine")
            .map((item, index) => (
              <div className="logBox" style={{margin:"2px 6px",padding:"10px"}} key={index}>
               
                {item.type === "log" && (
                  <p style={{ color: "#0aed0a", wordWrap: "break-word",padding:"0px",margin:"0px" }}>{item.data}</p>
                )}
                {item.type === "data" && (
                  <p style={{ color: "white", wordWrap: "break-word" }}>{item.data}</p>
                )}
                <h6 style={{ color: "white",paddingTop:"10px",margin:"0px",}}>{item.date}</h6>
              </div>
            ))}
        </TabPanel>
      </Box>
    </div>
  );
}
