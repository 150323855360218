import { BASE_URL_REDDIT } from "../utils/config";

// export const postReddit = async (data) => {
//   return fetch(`${BASE_URL_REDDIT}/Reddit_allChannel `, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ url: data }),
//   });
// };
export const getReddit = async () => {
  const res = await fetch(`${BASE_URL_REDDIT}/reddit_allProfile`, {
    method: "GET",
  });
  return res.json();
};

export const deleteReddit = async (id) => {
  return fetch(`${BASE_URL_REDDIT}/reddit_profile/${id}`, {
    method: "Delete",
  });
};

export const editUrgentReddit = async (data) => {
  return fetch(`${BASE_URL_REDDIT}/reddit_profile/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isUrgent: data?.isUrgent }),
  });
};

export const Status_Reddit= async () => {
  const response = await fetch(`${BASE_URL_REDDIT}/check_reddit_scheduler`,{
    method: "GET",
});
  return response.json()
};