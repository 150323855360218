import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField } from "@mui/material";
import { postCredentails, putCredentails } from "../../api/credentials";
import Alert from '@mui/material/Alert';
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { postTelegram } from "../../api/telegram";
import axios from 'axios';
import { BASE_URL_TELEGRAM } from "../../utils/config";


export const Telegram_Form = (props) => {
  const { handleClose, data_to_edit, setEditData, refetch } = props;
  const [url, setUrl] = useState("");
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [alert, setAlert] = useState(false);
  const [errormsg, setErrormsg] = useState(false)


  const notify = (type) => {
    toast.success(`${type} successfull`);
  };

  const { mutate, isLoading } = useMutation(postTelegram, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify("Uploaded");
        handleClose();
        refetch();
        setUrl("");
      }
    },
  });

  const { mutate: edit, isLoading: editing } = useMutation(putCredentails, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        refetch();
        handleClose();
        setEditData("");
        notify("Edited");
        setUrl("");
      }
    },
  });




  const handleChange = (e) => {
    setAlert(false)
    setUrl(e.target.value);
  };
  const handleSubmit = () => {
    if (data_to_edit?.id) {
      console.log({ url, id: data_to_edit.id })
      edit({ url, id: data_to_edit.id });
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
        setSelectedChannel(null);
      }, 5000);

      return;
    }
    mutate(url);
    console.log(url)
    setAlert(true)
    setTimeout(() => {
      setAlert(false)
      setSelectedChannel(null);
    }, 5000);
  };



  const handleSubmit2 = async (event) => {
    event.preventDefault();
    if (
      url.trim() === ""
    ) {
      setAlert(false)
      setErrormsg({ message: "Please fill the box before getting the channel info." });
      setTimeout(() => { setErrormsg(false); }, 3000);
    } else {
      try {
        console.log(url)
        const response = await axios.post(`${BASE_URL_TELEGRAM}/tele_input_channel_info`, {
          url: url,

        });
        const { status, msg, results } = response.data;
        console.log(msg)
        if (status === 'Success') {
          setSelectedChannel(response.data);
        }
      } catch (error) {
        console.error('Error fetching channel info:', error);
        setErrormsg({ message: "Network Error" });

        setTimeout(() => { setErrormsg(false); }, 3000);

      }
    }
  };




  useEffect(() => {
    if (data_to_edit.id) {
      setUrl(data_to_edit);
    }

  }, [data_to_edit]);

  return (
    <>
      <div className="form_modal_scraping">
        <div
          className="form_modal_scraping_close_icon"
          onClick={() => {
            handleClose();

            setEditData("");
          }}
        >
          <CloseIcon />

        </div>
        <h1>Get Telegram Info</h1>
        {alert &&
          <Alert severity={(selectedChannel.status === 'Success') ? 'success' : "error"}>{selectedChannel.status}</Alert>
        }
        {errormsg &&
          <Alert severity="error">{errormsg.message}</Alert>
        }
        <div className="scraping_input_container">
          <TextField
            required
            type="text"
            value={url}
            label="Site"
            className="scraping_input_site"
            onChange={handleChange}
          />
        </div>
        <Button variant="contained" color="success" onClick={handleSubmit2}>
          Get telegram info
        </Button>
        {selectedChannel !== null &&
          <div>
            <div className="scraping_input_container" style={{ border: '1px solid #dddddd', margin: "15px" }}>
              <div style={{ padding: "5px" }}>
                <p><strong style={{ color: "green" }}>Channel Name:</strong> {selectedChannel.channel_info.channel_name}</p>
                <p><strong style={{ color: "green" }}>Channel ID:</strong> {selectedChannel.channel_info.channel_id}</p>
                <p><strong style={{ color: "green" }}>URL:</strong> {selectedChannel.channel_info.url}</p>
                <p><strong style={{ color: "green" }}>Verified:</strong> {selectedChannel.channel_info.verified ? "Yes" : "No"}</p>
                <p><strong style={{ color: "green" }}>Total Users:</strong> {selectedChannel.channel_info.total_user}</p>
                <p><strong style={{ color: "green" }}>Channel Username:</strong> {selectedChannel.channel_info.channel_username}</p>
                <p><strong style={{ color: "green" }}>Is Group:</strong> {selectedChannel.channel_info.is_group ? "Yes" : "No"}</p>
                <p><strong style={{ color: "green" }}>Type:</strong> {selectedChannel.channel_info.type}</p>
              
              </div>

            </div>
            <div style={{ margin: "5px" }}>
              <Button onClick={handleClose} variant="contained" color="error">
                Cancel
              </Button>
              <Button variant="contained" color="success" onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </div>}


      </div>
    </>
  );
};
