import {useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField } from "@mui/material";
import Alert from '@mui/material/Alert';
import { toast } from "react-toastify";
import axios from 'axios';
import Countdown from 'react-countdown';
import { BASE_URL_TELEGRAM } from "../../utils/config";
// import { BASE_URL } from "../utils/config";


export const Telegram_session_Form = (props) => {
    const { refetch,handleClose2, data_to_edit, setEditData } = props;
    const [msg, setMsg] = useState(null);
    const [alert, setAlert] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        api_id: '',
        api_hash: '',
        phone_number: '',
    });
    const [otp, setOtp] = useState({
        otp: '',
    });

    const handleChangeotp = (event) => {
        const { name, value } = event.target;
        setOtp((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    const notify = (type) => {
        toast.success(`${type} successfull`);
    };

    const handleChange = (event) => {
        setShowWarning(false);
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handle_Submit = async (event) => {
        event.preventDefault();
        if (
            formData.name.trim() === '' ||
            formData.api_id.trim() === '' ||
            formData.api_hash.trim() === '' ||
            formData.phone_number.trim() === ''
        ) {
            setShowWarning(true);
        } else {
            try {

                const res = await axios.post(`${BASE_URL_TELEGRAM}/setup`, {
                    name: formData.name,
                    api_id: formData.api_id,
                    api_hash: formData.api_hash,
                    phone_number: formData.phone_number,
                });
                const results = res.data;
                setMsg(results);
                // console.log(results)F
                if (results.status === 'Success') {
                    
                    setTimeout(() => { setMsg(null); setAlert(true) }, 1000);
                } else { setTimeout(() => { setMsg(null) }, 3000); }

                // handleClose2()
            } catch (error) {
                console.error('Error fetching channel info:', error);
                setMsg({ message: "Network Error" });
                setTimeout(() => { setMsg(null) }, 3000);

            }
            setShowWarning(false);
            
        };
    }


    const handleSubmit2 = async () => {
        try {
            
            const res = await axios.post(`${BASE_URL_TELEGRAM}/signin`, {
                password: otp.otp,

            });
            const results = res.data;
            // console.log(results)
            setMsg(results);
            setAlert(false)
            notify("session file added")
            setTimeout(() => {
                setMsg(null);
                handleClose2()
            }, 5000);

        } catch (error) {
            console.error('Error fetching channel info:', error);
        }
    };


    const handleCountdownComplete = () => {
        setAlert(false);
    };
    const renderer = ({ seconds }) => {
        return <span style={{ fontSize: "1.5vh", color: "#e35706", display: "flex", fontWeight: "bold", justifyContent: "center" }}>{seconds} sec</span>;
    };
    return (
        <>
            <div className="form_modal_scraping">
                <div
                    className="form_modal_scraping_close_icon"
                    onClick={() => {
                        handleClose2();
                        setEditData("");
                    }}
                >
                    <CloseIcon />
                </div>
                {showWarning && (<Alert severity="warning">Please fill out the entire form before sending otp!</Alert>)}
                {msg !== null && (<Alert severity={msg.status==='Success'?"success":"error"}>{msg.message}</Alert>)}
                {!alert &&

                    <div className="scraping_input_container">

                        <TextField
                            required
                            type="text"
                            name="name"
                            value={formData.name}
                            label="Name"
                            variant="standard"
                            className="scraping_input_site"
                            onChange={handleChange}
                        />
                        <TextField
                            required
                            type="text"
                            name="api_id"
                            value={formData.api_id}
                            label="Api id"
                            variant="standard"
                            className="scraping_input_site"
                            onChange={handleChange}
                        />
                        <TextField
                            required
                            type="text"
                            name="api_hash"
                            value={formData.api_hash}
                            label="Api hash"
                            variant="standard"
                            className="scraping_input_site"
                            onChange={handleChange}
                        />
                        <TextField
                            required
                            type="text"
                            name="phone_number"
                            value={formData.phone_number}
                            label="Phone number (with country code)"
                            variant="standard"
                            className="scraping_input_site"
                            onChange={handleChange}
                        />


                        <Button variant="contained" color="success" onClick={handle_Submit}>
                            send otp (telegram app)
                        </Button>
                    </div>

                }
                {alert &&
                    <div>
                        <Countdown date={Date.now() + 60000} onComplete={handleCountdownComplete} renderer={renderer} />
                        <div className="scraping_input_container" style={{ border: '1px solid #dddddd' }}>
                            <TextField
                                required
                                type="text"
                                name="otp"
                                value={formData.otp}
                                label="Enter OTP"
                                variant="standard"
                                className="scraping_input_site"
                                onChange={handleChangeotp}
                            />
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <Button onClick={handleClose2} variant="contained" color="error">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit2} variant="contained" color="success">
                                Submit
                            </Button>
                        </div>
                    </div>
                }


            </div>
        </>
    );
};