import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { Badge, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import CloseIcon from "@mui/icons-material/Close";
import TableContainer from "@mui/material/TableContainer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
// import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  Box,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Delete, Add, Edit } from "@mui/icons-material";
import { BASE_URL } from "../../utils/config";

const columns = [
  { id: "darkweb_url", label: "URL", minWidth: 150, align: "left" },

  {
    id: "name",
    label: "Ransomware Group Name",
    minWidth: 150,
    align: "center",
  },
];

const steps = [
  "Website Information",
  "Website Categorization",
  "Data Extraction",
];

export default function ScrapingEngine() {
  const [Data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [Ur, setUr] = useState(null);
  const [Name, setName] = useState(null);
  const [WaitBeforeScrape, setWaitBeforeScrape] = useState(null);
  const [IsSPA, setIsSPA] = useState(false);
  const [Iterator, setIterator] = useState(null);
  const [Body, setBody] = useState([""]);
  const [Title, setTitle] = useState(null);

  const [UniqueUrl, setUniqueUrl] = useState(null);
  const [IsPagination, setIsPagination] = useState(false);
  const [IsScrollable, setIsScrollable] = useState(false);
  const [IsClickable, setIsClickable] = useState(false);
  const [WithNext, setWithNext] = useState(false);
  const [WithoutNext, setWithoutNext] = useState(false);
  const [NextXpath, setNextXpath] = useState(null);
  const [PaginationContainer, setPaginationContainer] = useState(null);
  const [PageTagName, setPageTagName] = useState(null);
  const [ClickableButtonXpath, setClickableButtonXpath] = useState(null);
  const [Id, setId] = useState("");
  const [item, setSelectItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // --------------------------
  const [mainPageDate, setMainPageDate] = useState(null);
  const [contentPageDate, setContentPageDate] = useState(null);
  const [regexInput, setRegexInput] = useState(null);
  const [publishdate,setPublishdate]= useState(null);
  const [publishdateRegex,setPublishdateRegex]= useState(null);
  const [publishdateTimer,setPublishdateTimer]= useState(null);

  const [showMainPageDateField, setShowMainPageDateField] = useState(false);
  const [showContentPageDateField, setShowContentPageDateField] = useState(false);
  const [Ishref, setIshref] = useState(true);
  // -----------------------------

  const open1 = Boolean(anchorEl);
  const navigate = useNavigate();
  const statusNotify = () => toast.warning("Busy!");
  const handleClick = (event, selectedItem) => {
    setAnchorEl(event.currentTarget);
    setSelectItem(selectedItem);
    // console.log(".............", selectedItem)
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    axios.get(`${BASE_URL}/allWebsite`).then((res) => setData(res.data));
  }

  const handleChangeUr = (e) => {
    setUr(e.target.value);
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeWaitBeforeScrape = (e) => {
    setWaitBeforeScrape(parseInt(e.target.value));
  };
  const handleChangeIsSPA = (e) => {
    setIsSPA(!IsSPA);
  };
  const handleChangeRadio = (e) => {
    const val = e.target.value;
    if (val === "IsPagination") {
      setIsPagination(true);
      setIsClickable(false);
      setIsScrollable(false);
    }
    if (val === "IsScrollable") {
      setIsPagination(false);
      setIsClickable(false);
      setIsScrollable(true);
      setWithNext(false);
      setWithoutNext(false);
    }
    if (val === "IsClickable") {
      setIsPagination(false);
      setIsClickable(true);
      setIsScrollable(false);
      setWithNext(false);
      setWithoutNext(false);
    }
  };
  const handleChangeRadio2 = (e) => {
    const val = e.target.value;
    if (val === "WithNext") {
      setWithNext(true);
      setWithoutNext(false);
    }
    if (val === "WithoutNext") {
      setWithNext(false);
      setWithoutNext(true);
    }
  };
  const handleChangeIterator = (e) => {
    setIterator(e.target.value);
  };
  const handleChangeNextXpath = (e) => {
    setNextXpath(e.target.value);
  };
  const handleChangePaginationContainer = (e) => {
    setPaginationContainer(e.target.value);
  };
  const handleChangePageTagName = (e) => {
    setPageTagName(e.target.value);
  };
  const handleChangeClickableButtonXpath = (e) => {
    setClickableButtonXpath(e.target.value);
  };
  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeDate = (e) => {
    setContentPageDate(e.target.value);
  };
  const handleChangeUnique_Url = (e) => {
    setUniqueUrl(e.target.value);
    console.log(e.target.value)
  }
  const handleChangeBody = (e, index) => {
    setBody((Body) => Object.assign([], Body, { [index]: e }));
  };
  const addInput = () => {
    setBody((Body) => [...Body, ""]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setId("");
  };
  const handleChangeIshref = (e) => {
    setIshref(!Ishref);
  };
  
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (value === 'mainPageCheckbox' && checked) {
      setShowMainPageDateField(true);
      setShowContentPageDateField(false);
      setContentPageDate(null);
      setRegexInput(null)
    } else if (value === 'contentPageCheckbox' && checked) {
      setShowMainPageDateField(false);
      setShowContentPageDateField(true);
      setMainPageDate(null);
      setRegexInput(null)
    } else {
      setShowMainPageDateField(false);
      setShowContentPageDateField(false);
      setMainPageDate(null);
      setContentPageDate(null);
      setRegexInput(null)
    }
  };

  const handleMainPageDatetChange = (event) => {
    const inputValue = event.target.value;
    if (showMainPageDateField) {
      setMainPageDate(inputValue);
      console.log("mainpage:", inputValue)
    } else if (showContentPageDateField) {
      setContentPageDate(inputValue);
      console.log("containpahe:", inputValue)
    }
  }
  
  const handlePublishdateChange=(event)=>{
      const inputValue = event.target.value;
      setPublishdate(inputValue);
    
  }
  const handlePublishdateTimerChange=(event)=>{
    const inputValue = event.target.value;
    setPublishdateTimer(inputValue);
  
}
const handlePublishDateRegexInputChange=(event)=>{
  const inputValue = event.target.value;
  setPublishdateRegex(inputValue);

}
  const handleRegexInputChange = (event) => {
    const inputValue = event.target.value;
    setRegexInput(inputValue);
    console.log("regex:", inputValue)
    // if (showMainPageDateField) {
    //   setMainPageDate(inputValue);
    // } else if (showContentPageDateField) {
    //   setContentPageDate(inputValue);
    // }
  };
  // console.log("reg:", regexInput, "main", mainPageDate, "cont:", contentPageDate,"href",Ishref,"publishdate",publishdate,"pu_reg",publishdateRegex,"pub_timer_date",publishdateTimer)

  async function handleSave() {
    if (Name === "" && Ur === "") {
      alert("Form cannot be completely empty");
      return;
    }
    const body = [];
    Body.forEach((value, index) => {
      body[index] = value;
    });
    
    const object = {
      name: Name,
      isSPA: IsSPA,
      darkweb_url: Ur,
      isUrgent: false,
      body_xpath: body,

      home_page_date_xpath: mainPageDate,
      date_xpath: contentPageDate,
      date_regex: regexInput,
      pubdate_regex: publishdateRegex,
      pubdate_xpath: publishdate,
      pubdate_timer_xpath: publishdateTimer,
      ishref: Ishref,

      iterator: Iterator,
      title_xpath: Title,
      is_nextbtn: WithNext,
      clickable: IsClickable,
      scrollable: IsScrollable,
      pagination: IsPagination,
      xpath_of_next_btn: NextXpath,
      tag_name_of_pages: PageTagName,
      waitTime: parseInt(WaitBeforeScrape),
      clickable_btn_xpath: ClickableButtonXpath,
      xpath_of_pagination_container: PaginationContainer,
      unique_url: UniqueUrl
    };
    if (Id === "") {
      // console.log(object)
      const resp = await axios.post(
        "https://mtapi.emsec.dev/darkWebsite",
        object
      );
    } else {
      const resp = await axios.put(
        "https://mtapi.emsec.dev/darkWebsite/" + Id,
        object
      );
      if (resp.data === "Website updated") {
        alert("Website Properties Updated");
      }
    }

    // window.location.reload(false);
    loadData();
    setOpen(false);
    setState(0);
    setName(null);
    setIsSPA(false);
    setUr(null);
    setBody("");

    setMainPageDate(null)
    setContentPageDate(null);
    setRegexInput(null);
    setPublishdateRegex(null);
    setPublishdate(null);
    setPublishdateTimer(null)
    setIshref(true)

    setIterator(null);
    setTitle(null);
    setWithNext(false);
    setIsClickable(false);
    setIsScrollable(false);
    setIsPagination(false);
    setNextXpath(null);
    setPageTagName(null);
    setWaitBeforeScrape(null);
    setClickableButtonXpath(null);
    setPaginationContainer(null);
    setUniqueUrl(null);
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [darkwebforum, setDarkwebforum] = useState(false);
  const [state, setState] = useState(0);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const notify = (type) => {
    toast.success(`${type} successfully updated`);
  };
  async function handleUrgent(e) {
    const data = e;
    data["isUrgent"] = true;
    const resp = await axios.put(
      "https://mtapi.emsec.dev/darkWebsite/" + data["_id"]["$oid"],
      data
    );
    console.log(resp)
    if (resp.data === "Website updated") {

      notify("Website Marked As Urgent")
    }
    // window.location.reload(false);
    loadData()

  }

  const handleHeadful = async (e) => {
    const headfulData = {
      name: e?.name,
      url: e?.darkweb_url,
      waitTime: 150,
      siteType: "ransomware",
      testing: false,
    };
    try {
      const firstApiResponse = await axios.get(
        "https://headapi.emsec.dev/status"
      );
      console.log(firstApiResponse.data, "1");
      if (firstApiResponse.data.status === "busy") {
        statusNotify();
      }
      if (firstApiResponse.data.status === "free") {
        const secondApiResponse = await axios.post(
          "https://headapi.emsec.dev/startscrapping",
          headfulData
        );
        navigate("/headful", { replace: true });
        console.log("Response from the second API:", secondApiResponse);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleTest = async (e) => {
    const headfulData = {
      name: e?.name,
      url: e?.darkweb_url,
      waitTime: 150,
      siteType: "ransomware",
      testing: true,
    };
    try {
      const firstApiResponse = await axios.get(
        "https://headapi.emsec.dev/status"
      );
      console.log(firstApiResponse.data, "1");
      if (firstApiResponse.data.status === "busy") {
        statusNotify();
      }

      if (firstApiResponse.data.status === "free") {
        const secondApiResponse = await axios.post(
          "https://headapi.emsec.dev/startscrapping",
          headfulData
        );
        navigate("/headful", { replace: true });

        console.log("Response from the second API:", secondApiResponse);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  function handleEdit(e) {
    console.log(e);
    setId(e["_id"]["$oid"]);
    setState(0);
    setName(e.name);
    setIsSPA(e.isSPA);
    setUr(e.darkweb_url);
    setBody(e.body_xpath);
    
    setMainPageDate(e.home_page_date_xpath);
    setContentPageDate(e.date_xpath);
    setRegexInput(e.date_regex);
    setPublishdateRegex(e.pubdate_regex);
    setPublishdate(e.pubdate_xpath);
    setPublishdateTimer(e.pubdate_timer_xpath)
    setIshref(e.ishref);
  
    setIterator(e.iterator);
    setTitle(e.title_xpath);
    setWithNext(e.is_nextbtn);
    setIsClickable(e.clickable);
    setIsScrollable(e.scrollable);
    setIsPagination(e.pagination);
    setNextXpath(e.xpath_of_next_btn);
    setPageTagName(e.tag_name_of_pages);
    setWaitBeforeScrape(e.waitTime);
    setClickableButtonXpath(e.clickable_btn_xpath);
    setPaginationContainer(e.xpath_of_pagination_container);
    setUniqueUrl(e.unique_url)
    setOpen(true);

  }
  async function handleDelete(e) {
    const data = e;
    const resp = await axios.delete(
      "https://mtapi.emsec.dev/darkWebsite/" + data["_id"]["$oid"]
    );
    window.location.reload(false);
  }
  if (!Data) return null;

  return (
    <div className="login_container">
      <ImportantDevicesOutlinedIcon sx={{ fontSize: 40 }} />
      <div className="AddButton">
        {/* <Button
          variant="contained"
          sx={{
            fontWeight: "600",
            marginRight: "1rem",
            backgroundColor: "#6F4EFA",
          }}
          onClick={() => setDarkwebforum(!darkwebforum)}
        >
          {darkwebforum ? "Dark Web" : "Dark Web Forum"}
        </Button> */}
        <Button
          variant="contained"
          sx={{
            width: "18vh",
            height: "5vh",
            fontSize: "2vh",
            fontWeight: "600",
            backgroundColor: "#FC3B93",
          }}
          onClick={handleClickOpen}
        >
          Add URL
        </Button>

        {open && (
          <div className="form_modal_scraping">
            <div
              className="form_modal_scraping_close_icon"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={state} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {state === 0 && (
              <div className="scraping_input_container">
                <TextField
                  required
                  type="text"
                  label="Name"
                  value={Name}
                  variant="standard"
                  placeholder="Name&#42;"
                  className="scraping_input"
                  onChange={handleChangeName}
                />
                <TextField
                  name="Ur"
                  required
                  label="URL"
                  type="text"
                  value={Ur}
                  variant="standard"
                  placeholder="URL&#42;"
                  className="scraping_input"
                  onChange={handleChangeUr}
                />
                <TextField
                  required
                  type="number"
                  variant="standard"
                  name="WaitBeforeScrape"
                  value={WaitBeforeScrape}
                  className="scraping_input"
                  label="Wait Time Before Scraping"
                  onChange={handleChangeWaitBeforeScrape}
                  placeholder="Wait Time Before Scrape&#42;"
                />
              </div>
            )}
            {state === 1 && (
              <>
                <div className="scraping_input_container">
                  <h3>Website Categorization</h3>
                </div>
                <div className="scraping_input_container">
                  <label> Is SPA?</label>
                  <input
                    name="IsSPA"
                    value={IsSPA}
                    type="checkbox"
                    className="Checkbox"
                    checked={IsSPA}
                    onChange={handleChangeIsSPA}
                  />
                </div>
                <div className="scraping_input_container" style={{ display: 'flex', alignItems:"center" ,position: 'relative'}}>
                  <div>
                  <label>
                    Is href of Content page?

                  </label>
                  <input
                    name="Ishref"
                    value={Ishref}
                    type="checkbox"
                    className="Checkbox"
                    checked={Ishref}
                    onChange={handleChangeIshref}
                  />
                  </div>
                  

                  <span
                    style={{
                      fontSize: '10px', // Adjusted from size to fontSize
                      borderRadius: '1px',
                      padding: '0px',
                      position: 'absolute',
                      textAlign: 'center',
                      left: '50%',
                      color: 'skyblue'
                    }}
                  >
                    
                    (HTML anchor tags with the href attribute enable clickable links, directing users to different pages or resources(content page) while browsing websites.They are commonly present on the home page)
                  </span>


                </div>

                <div
                  className="scraping_input_container"
                  onChange={handleChangeRadio}
                >
                  <input
                    name="choice"
                    type="radio"
                    value={"IsPagination"}
                    checked={IsPagination}
                  ></input>
                  <label htmlFor="choice">Pagination</label>
                  <input
                    name="choice"
                    type="radio"
                    value={"IsClickable"}
                    checked={IsClickable}
                  ></input>
                  <label htmlFor="choice">Clickable</label>
                  <input
                    name="choice"
                    type="radio"
                    value={"IsScrollable"}
                    checked={IsScrollable}
                  ></input>
                  <label htmlFor="choice">Scrollable</label>
                </div>

                {IsPagination && (
                  <div
                    className="scraping_input_container"
                    onChange={handleChangeRadio2}
                  >
                    <input
                      name="choice2"
                      type="radio"
                      value={"WithNext"}
                      checked={WithNext}
                    ></input>
                    <label htmlFor="choice">With Next Button</label>
                    <input
                      name="choice2"
                      type="radio"
                      value={"WithoutNext"}
                      checked={WithoutNext}
                    ></input>
                    <label htmlFor="choice">Without Next Button</label>
                  </div>
                )}
                {WithNext && IsPagination ? (
                  <div className="scraping_input_container">
                    <TextField
                      type="text"
                      required={true}
                      name="NextXpath"
                      value={NextXpath}
                      variant="standard"
                      className="scraping_input"
                      onChange={handleChangeNextXpath}
                      label="Next Button XPath"
                      placeholder="Next Button XPath&#42;"
                    />
                  </div>
                ) : null}

                {WithoutNext && IsPagination ? (
                  <div className="scraping_input_container">
                    <TextField
                      required
                      type="text"
                      variant="standard"
                      name="PaginationXpath"
                      className="scraping_input"
                      value={PaginationContainer}
                      onChange={handleChangePaginationContainer}
                      label="Pagination Container XPath"
                      placeholder="Pagination Container XPath&#42;"
                    />
                    <TextField
                      required
                      type="text"
                      variant="standard"
                      name="PageTagName"
                      value={PageTagName}
                      className="scraping_input"
                      onChange={handleChangePageTagName}
                      label="Page Tagname XPath"
                      placeholder="Page Tagname XPath&#42;"
                    />
                  </div>
                ) : null}

                {IsClickable ? (
                  <div className="scraping_input_container">
                    <TextField
                      required
                      type="text"
                      variant="standard"
                      className="scraping_input"
                      name="ClickableButtonXpath"
                      value={ClickableButtonXpath}
                      label="Clickable Button XPath"
                      onChange={handleChangeClickableButtonXpath}
                      placeholder="Clickable Button XPath&#42;"
                    />
                  </div>
                ) : null}
              </>
            )}
            {state === 2 && (
              <div style={{
                maxHeight: "700px",
                overflowY: "auto"
              }}>
                <div className="scraping_input_container">
                  <h3 style={{ marginBottom: '2px' }}>Data Extraction</h3>
                </div>
                <div className="scraping_input_container">
                  <TextField
                    type="text"
                    name="Iterator"
                    value={Iterator}
                    variant="standard"
                    className="scraping_input"
                    onChange={handleChangeIterator}
                    label="Post Iterator XPath"
                    placeholder="Post Iterator XPath"
                  />
                  <TextField
                    type="text"
                    name="Title"
                    value={Title}
                    variant="standard"
                    className="scraping_input"
                    onChange={handleChangeTitle}
                    label="Title XPath"
                    placeholder="Title XPath"
                  />
                </div>
                <div className="scraping_input_container">
                  <h3 style={{ marginBottom: '3px' }}>Body Paths</h3>
                  <Button
                    style={{ marginBottom: '2px' }}
                    color="primary"
                    onClick={() => setBody((oldSP) => [...oldSP, ""])}
                  >
                    <Add />
                  </Button>
                  {Body.map((value, index) => (
                    // <TextField
                    //   type="text"
                    //   value={value}
                    //   variant="standard"
                    //   className="scraping_input"
                    //   label="Body XPath"
                    //   placeholder="Body XPath"
                    //   onChange={(evt) => handleChangeBody(evt.target.value, index)}
                    // />
                    <TextField
                      required
                      type="text"
                      value={value}
                      variant="standard"
                      className="scraping_input"
                      label="Body XPath"
                      placeholder="Body XPath"
                      onChange={(evt) =>
                        handleChangeBody(evt.target.value, index)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                setBody((oldSP) =>
                                  oldSP.filter((s, i) => i != index)
                                )
                              }
                            >
                              <Delete sx={{ color: "red" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                  <hr />


                  <div>

                    <h3>Main Page Or Content Page DateXapth*</h3>
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <div>
                        <input
                          name="mainPageCheckbox"
                          type="checkbox"
                          value="mainPageCheckbox"
                          checked={showMainPageDateField}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="mainPageCheckbox">Date On Main Page</label>
                      </div>
                      {!IsSPA && <div>
                        <input
                          name="contentPageCheckbox"
                          type="checkbox"
                          value="contentPageCheckbox"
                          checked={showContentPageDateField}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="contentPageCheckbox">Date On Content Page</label>
                      </div>}
                      
                    </div>

                    {showMainPageDateField && (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          name="mainPageDate"
                          type="text"
                          variant="standard"
                          label="Main Page DateXPath"
                          value={mainPageDate}
                          onChange={handleMainPageDatetChange}
                        />
                        <TextField
                          name="regexInput"
                          type="text"
                          variant="standard"
                          label="Regex Pattern (if applicable)"
                          placeholder="e.g. r'added: (\d{4}-\d{2}-\d{2})'"
                          value={regexInput}
                          onChange={handleRegexInputChange}
                        />
                      </div>
                    )}

                    {showContentPageDateField && (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <TextField
                          name="contentPageDate"
                          type="text"
                          variant="standard"
                          label="Content Page DateXPath"
                          value={contentPageDate}
                          onChange={handleMainPageDatetChange}
                        />
                        <TextField
                          name="regexInput"
                          type="text"
                          variant="standard"
                          label="Regex Pattern (if applicable)"
                          placeholder="e.g. r'added: (\d{4}-\d{2}-\d{2})'"
                          value={regexInput}
                          onChange={handleRegexInputChange}
                        />
                      </div>

                    )}
                    <div>
                      <h3 style={{ marginBottom: '2px' }}>Publish DateXpath</h3>
                      <div style={{ display: 'flex', gap: '10px', }}>
                        <TextField
                          type="text"
                          name="publishdate"
                          variant="standard"
                          className="scraping_input"
                          label="Publish date"
                          placeholder="Publish Date Xpath"
                          value={publishdate}
                          onChange={handlePublishdateChange}
                        />
                       
                        <TextField
                          type="text"
                          name="publishdate_regex"
                          variant="standard"
                          className="scraping_input"
                          label="Regex Pattern (if applicable)"
                          placeholder="e.g. r''published date: (\d{4}-\d{2}-\d{2}))'"
                          value={publishdateRegex}
                          onChange={handlePublishDateRegexInputChange}
                        />
                      </div>
                      <TextField
                          type="text"
                          name="publishdate_Timer"
                          variant="standard"
                          className="scraping_input"
                          label="Publish Date Timer Xpath (if applicable)"
                          placeholder="Publish Date Timer Xpath"
                          value={publishdateTimer}
                          onChange={handlePublishdateTimerChange}
                        />
                    </div>

                  </div>
                </div>
                {IsSPA && <><div className="scraping_input_container">
                  <h3 style={{ marginBottom: '2px' }}>Unique Url</h3>
                  <TextField
                    type="text"
                    name="UniqueUrl"
                    value={UniqueUrl}
                    variant="standard"
                    className="scraping_input"
                    onChange={handleChangeUnique_Url}
                    label="Unique Url"
                    placeholder="Unique Url"
                  />

                </div>
                </>}

              </div>
            )}
            <div className="scraping_button_container1">
              {/* <Button onClick={addInput} variant="contained">
                Add Body Field
              </Button> */}
              <Button
                onClick={() =>
                  state === 0 ? handleClose() : setState(state - 1)
                }
                variant="contained"
                color="error"
              >
                {state === 0 ? "Close" : "Prev"}
              </Button>
              <Button
                onClick={() =>
                  state === steps.length - 1
                    ? handleSave()
                    : setState(state + 1)
                }
                variant="contained"
                color="success"
              >
                {state === steps.length - 1 ? "Save" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div>
        <Paper
          sx={{
            width: "99%",
            overflow: "hidden",
          }}
        >
          <TableContainer
            sx={{
              width: "95vw",
              height: "79vh",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ color: "white" }}>
                <TableRow sx={{ color: "white" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{ color: "white" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell style={{ minWidth: 150 }} sx={{ color: "white" }}>
                    Actions
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody sx={{ color: "white" }}>
                {Data.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      sx={{ color: "white" }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              color: "white",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : column.id === "name"
                                ? value?.toUpperCase()
                                : value}
                          </TableCell>

                        );
                      })}
                      <TableCell
                        style={{
                          // gap: "1rem",
                          minWidth: 150,
                          display: "flex",
                          alignItems: "center",

                        }}
                        sx={{ color: "white" }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{
                            height: "fit-content",
                            fontSize: "1.5vh",
                            fontWeight: "600",
                          }}
                          id="basic-button"
                          aria-controls={open1 ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open1 ? "true" : undefined}
                          onClick={(e) => handleClick(e, row)}
                        >
                          Actions
                        </Button>
                        <div style={{ marginLeft: "10px" }}>{<Badge color={row?.siteStatus === "up" ? 'success' : "error"} variant="dot" />}</div>

                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open1}
                          onClose={handleClose1}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose1();
                              handleUrgent(item);
                            }}
                          >
                            Mark as Urgent
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose1();
                              handleDelete(item);
                            }}
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleEdit(item);
                              handleClose1();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose1();
                              handleHeadful(item);
                            }}
                          >
                            Headful Scraping
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose1();
                              handleTest(item);
                            }}
                          >
                            Test
                          </MenuItem>
                        </Menu>
                        {/* <Button
                          color="primary"
                          variant="contained"
                          disabled={row["isUrgent"] === true}
                          onClick={(evt) => handleUrgent(row)}
                        >
                          Mark as urgent
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleEdit(row)}>
                            <Edit/>
                        </Button>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={(evt) => handleDelete(row)}
                        >
                          <Delete/>
                        </Button>
                        <Button color="error"
                          variant="contained" onClick={()=> handleHeadful(row)}>Headful</Button>
                        <Button color="error"
                          variant="contained" onClick={()=> handleTest(row)} >Test</Button> */}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* <TablePagination
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 100]}
          count={Math.round(Data.length / rowsPerPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            left: 0,
            right: 0,
            color: "white",
            botton: "-100vh",
            marginRight: "5vw",
            position: "absolute",
          }}
        /> */}
        <Pagination
          page={page + 1}
          size="large"
          count={Math.ceil(Data.length / 10)}
          color="secondary"
          variant="outline"
          shape="rounded"
          onChange={(event, value) => setPage(value - 1)}
        />
      </div>
    </div>
  );
}
