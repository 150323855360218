import { BASE_URL } from "../utils/config";

export const postCredentails = async (data) => {
  return fetch(`${BASE_URL}/credential`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data }),
  });
};

export const getCredentails = async ({ page, limit }) => {
  const res = await fetch(
    `${BASE_URL}/credential?page=${page}&limit=${limit}`,
    {
      method: "GET",
    }
  );
  return res.json();
};

export const deleteCredentails = async (id) => {
  return fetch(`${BASE_URL}/credential`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id }),
  });
};

export const putCredentails = async (data) => {
  return fetch(`${BASE_URL}/credential`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data }),
  });
};
