import { Button, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "../../styles/login.css";
const Headful = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [password, setPassword] = useState("");
const starter="http://root:"
const ending="@159.65.144.115:6080/"

  useEffect(() => {
    const fetchPassword = async () => {
      const res = await fetch("https://headapi.emsec.dev/get_psw", {
        method: "GET",
      });
      const response = await res.json();
      console.log(response);
      setPassword(response);
    };
    fetchPassword();
    if(sessionStorage.getItem("loggedin") !== "true"){
      navigate("/");
    }
  }, []);

  const handleClick = async () => {
    const res = await fetch(`https://headapi.emsec.dev/continuescarpping`, {
      method: "GET",
    });
    const response = await res.json();
    console.log(response);
  };
  return (
    <>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {
          password && (
            <div className="login_container">
              <iframe
                frameBorder="0"
                // src="https://head.emsec.dev/"
                src={`https://159.65.144.115:6081`}
                title="Scraping Engine (HeadMode)"
                style={{ height: "85%", width: "70%" }}
              />
              <Button
                variant="contained"
                color="success"
                sx={{
                  width: "35vh",
                  height: "5vh",
                  fontSize: "1.5vh",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
                onClick={handleClick}
              >
                Continue Scraping
              </Button>
              <Typography variant="caption" gutterBottom>
                If Can't connect then
                <Link href={`${starter}` + `${password}` + `${ending}`} target="_blank">click here</Link>

              </Typography>
            </div>
          )
        }
        
      </div>
    </>
  );
};

export default Headful;
