import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Box } from "@mui/system";
import { CardContent, Typography } from "@mui/material";
import DnsIcon from "@mui/icons-material/Dns";
import ForumIcon from "@mui/icons-material/Forum";
import TelegramIcon from "@mui/icons-material/Telegram";
import RedditIcon from "@mui/icons-material/Reddit";
import FaceIcon from "@mui/icons-material/Face";
import LanguageIcon from "@mui/icons-material/Language";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import WebIcon from '@mui/icons-material/Web';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import "../../styles/dash.css";

export default function Dashboard() {
  const navigate = useNavigate();

  const handleClick = (link) => {
    if (link === "") {
      alert("Coming Soon....");
      return;
    }
    if (link.includes("http")) {
      window.open(link, "_blank");
      return;
    }
    navigate(`${link}`);
  };

  useEffect(() => {
    if(sessionStorage.getItem("loggedin") !== "true"){
      navigate("/");
    }
  }, []);

  return (
    <div className="login_container">
      <p className="dash_header">Validation Portal</p>

      <div className="Card_container">
        <DashCard
          subText=""
          name="Services Status"
          handleClick={handleClick}
          link="/Services_status"
          icon={<DnsIcon />}
        />
        <DashCard
          name="Scraping Engine"
          link="/Scraping_Engine"
          handleClick={handleClick}
          subText="(Dark Web Ransomware Blogs)"
          icon={<ImportantDevicesOutlinedIcon />}
        />
        {/* <DashCard
          name="Scraping Engine "
          handleClick={handleClick}
          subText="(Dark Web Forums)"
          link="/Scraping_Engine_Forums"
          icon={<ForumIcon />}
        /> */}
        <DashCard
          name="Scraping Engine"
          handleClick={handleClick}
          subText="(Dark Web Forums)"
          link="/headfulBrowser"
          icon={<WebIcon/>}
        />
        <DashCard
          subText="(Telegram)"
          name="Scraping Engine "
          handleClick={handleClick}
          link="/Scraping_Engine_Telegram"
          icon={<TelegramIcon />}
        />
       
        <DashCard
          subText="(Discord)"
          name="Scraping Engine"
          handleClick={handleClick}
          link="/Scraping_Engine_Discord"
          icon={<FaceIcon />}
        />
        <DashCard
          subText="(Reddit)"
          name="Scraping Engine"
          handleClick={handleClick}
          link="/Scraping_Engine_Reddit"
          icon={<RedditIcon />}
        />
        <DashCard
          subText="(Threads)"
          name="Scraping Engine"
          handleClick={handleClick}
          link="/Scraping_Engine_Thread"
          icon={<AlternateEmailIcon />}
        />
        <DashCard
          name="I2P"
          handleClick={handleClick}
          subText="(I2P)"
          link="/i2p"
          icon={<ForumIcon />}
        />
       
        <DashCard
          subText=""
          name="Credential Manager"
          link="/Credential_manager"
          handleClick={handleClick}
          icon={<FeaturedPlayListOutlinedIcon />}
        />
        <DashCard
          subText=""
          name="Link Validation"
          link="/link_validation"
          handleClick={handleClick}
          icon={<FeaturedPlayListOutlinedIcon />}
        />
        <DashCard
          subText=""
          handleClick={handleClick}
          name="Scraping Engine Logs"
          link="/Scraping_Engine_Logs"
          icon={<ReceiptLongOutlinedIcon />}
        />
         <DashCard
          subText=""
          handleClick={handleClick}
          name="Scheduler Alert Center"
          link="/Alert_center"
          icon={<WarningAmberIcon />}
        />
         <DashCard
          subText=""
          handleClick={handleClick}
          name="Stealer Log"
          link="/Stealer_log_headful"
          icon={<PersonSearchIcon/>}
        />

        <DashCard
          subText=""
          name="Mariana Trench"
          handleClick={handleClick}
          link="https://mt.emsec.dev/"
          icon={<LanguageIcon />}
        />
      </div>
    </div>
  );
}

const DashCard = (props) => {
  const { name, link, subText, handleClick, icon } = props;
  return (
    <>
      <div className="card" onClick={() => handleClick(`${link}`)}>
        <div>{icon}</div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography sx={{ fontSize: "1.5vw" }}>{name}</Typography>
            <Typography sx={{ fontSize: "1vw", opacity: 0.5 }}>
              {subText}
            </Typography>
          </CardContent>
        </Box>
      </div>
    </>
  );
};
