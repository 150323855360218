import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DnsIcon from '@mui/icons-material/Dns';
import ForumIcon from "@mui/icons-material/Forum";
import TelegramIcon from "@mui/icons-material/Telegram";
import LanguageIcon from "@mui/icons-material/Language";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
import RedditIcon from '@mui/icons-material/Reddit';
import FaceIcon from '@mui/icons-material/Face'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WebIcon from '@mui/icons-material/Web';
import { AlternateEmail } from '@mui/icons-material';
import Logo from "../../assets/logo.png";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import "../../styles/nav.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

const navigationStack = [
  {
    name: "Dashboard",
    link: "/Dashboard",
    icon: <DashboardOutlinedIcon />,
  },

  {
    name: "Services Status",
    link: "/Services_status",
    icon: <DnsIcon />,
  },
  {
    name: "Credential Manager",
    link: "/Credential_manager",
    icon: <FeaturedPlayListOutlinedIcon />,
  },
  
  {
    name: "Link Validation",
    link: "/link_validation",
    icon: <FeaturedPlayListOutlinedIcon />,
  },
  {
    name: "Scraping Engine Logs",
    link: "/Scraping_Engine_Logs",
    icon: <ReceiptLongOutlinedIcon />,
  },
  {
    name: "Scraping Engine (Dark Web Ransomware Blogs)",
    link: "/Scraping_Engine",
    icon: <ImportantDevicesOutlinedIcon />,
  },
  {
    name: "Scraping Engine(Dark Web Forums)",
    link: "/headfulBrowser",
    icon: <ForumIcon />,
  },
  // {
  //   name: "Headful Browser",
  //   link: "/headfulBrowser",
  //   icon: <WebIcon />,
  // },
  {
    name: "Scraping Engine(i2p)",
    link: "/i2p",
    icon: <ForumIcon/>,
  },
  {
    name: "Scraping Engine(Telegram)",
    link: "/Scraping_Engine_Telegram",
    icon: <TelegramIcon />,
  },
  
  {
    name: "Scraping Engine(Discord)",
    link: "/Scraping_Engine_Discord",
    icon: <FaceIcon />,
  },
  {
    name: "Scraping Engine(Reddit)",
    link: "/Scraping_Engine_Reddit",
    icon: <RedditIcon />,
  },
  {
    name: "Scraping Engine(Threads)",
    link: "/Scraping_Engine_Thread",
    icon: <AlternateEmail/>,
  },
  {
    name: "Scheduler Alert Center",
    link: "/Alert_center",
    icon: <WarningAmberIcon/>,
  },
  {
    name: "Stealer Log",
    link: "/Stealer_log_headful",
    icon: <PersonSearchIcon/>,
  },
  {
    name: "Mariana Trench",
    link: "",
    a: "https://mt.emsec.dev/",
    icon: <LanguageIcon />,
  },
];

export default function NavBar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    right: false,
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const location = useLocation();

  const list = (anchor) => (
    <div className={"navbar_menu_container"}>
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 360 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div style={{ alignItems: "end" }}>
          <img alt="logo image" src={Logo} className={"navbar_logo"} />
          <br />
          <p style={{ fontSize: "0.8rem", marginLeft: "1rem" }}>
            MARIANA TRENCH{" "}
            <span
              style={{
                fontSize: "0.3rem",
                paddingBottom: "1px",
              }}
            >
              (Beta)
            </span>
          </p>
        </div>
        <hr />
        <List>
          {navigationStack?.map((item, i) => (
            <ListItem disablePadding key={i}>
              <ListItemButton
                disabled={
                  location.pathname === item.link ||
                  (item.link === "" && !item.a)
                }
                onClick={(e) => {
                  e.stopPropagation();
                  {
                    item.a
                      ? (window.location.href = `${item.a}`)
                      : navigate(item.link);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname === item.link ? "#F5F6ED" : "#fff",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{
                    color: location.pathname === item.link ? "#F5F6ED" : "#fff",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Box>
    </div>
  );

  return (
    <div className="navbar_container">
      {/* options for drawer position ["left", "right", "top", "bottom"] */}
      {["left"].map((anchor, i) => (
        <React.Fragment key={i}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon sx={{ color: "white" }} />
          </Button>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AccountCircleOutlinedIcon sx={{ color: "white" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem onClick={() => { handleClose(); navigate('/');}}>Logout</MenuItem>
        <MenuItem onClick={() => { handleClose(); navigate('/addUser');}}>Add User</MenuItem>
      </Menu>
    </div>
  );
}
