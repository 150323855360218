import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "../../styles/status_styles.css";
import { BASE_URL_TELEGRAM } from '../../utils/config';
export const TelegramServiceStatus = (props) => {
    const { data2, isLoading2, refetch2 } = props
    console.log("---------------Telegram---------------", data2)
    const handleStart = async () => {
        const response = await fetch(`${BASE_URL_TELEGRAM}/start_telegram_scheduler`, {
            method: 'POST',
        });
        const data = await response.json();
        refetch2()

    };

    const handleStop = async () => {
        const response = await fetch(`${BASE_URL_TELEGRAM}/stop_telegram_scheduler`, {
            method: 'POST',
        });
        const data = await response.json();
        refetch2()

    };


    const statusData2 = (data2!== undefined && data2?.status !== "server down") ? [data2] : [{

        "service_name": "please start server",
        "message": "please start server",
        "server_ip": "please start server",
        "server_port": "please start server",
        "scraping": "please start server"

    }];



    const schedulerStatus = isLoading2 ? 'Loading...' : data2?.message || 'Unknown';
    const startStopButtonText = schedulerStatus === 'Scheduler is running!' ? 'Stop' : 'Start';
    const startStopButtonColor = schedulerStatus === 'Scheduler is running!' ? 'success' : 'error';
    console.log(data2)
    return (
        // <div className="thread_container">
        <div >

            <div style={{
             
             border: (data2?.status === "server down") &&  data2.message !== "Scheduler is running!"  ? '1px solid red' : '1px solid green'
            
              
            }}
            >
                <div style={{ overflow: 'auto' }}>
                    <TableContainer component={Paper}>
                        <Table
                            style={{
                                borderCollapse: 'collapse',
                                width: '100%',
                                margin: '20px 0',
                                fontSize: '18px',
                                padding: '16px',
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        S.No.
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Service Name
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Status
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Server IP
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Server Port
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Current Scraping
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(statusData2.map((status, i) => (
                                    <TableRow key={status.service_name}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{status.service_name}</TableCell>
                                        <TableCell style={{color: data2 === undefined || data2?.message !== "Scheduler is running!" ? ' red' : '#32CD32'}}>{status.message}</TableCell>
                                        <TableCell>{status.server_ip}</TableCell>
                                        <TableCell>{status.server_port}</TableCell>
                                        <TableCell>{ status.scraping===null ? status.message : status.scraping }</TableCell>
                                        <TableCell>

                                            <Button
                                                color={startStopButtonColor}
                                                variant="contained"
                                                sx={{
                                                    width: '45%',
                                                    fontSize: '1.5vh',
                                                    fontWeight: '600',
                                                    height: 'fit-content',
                                                }}
                                                onClick={schedulerStatus === 'Scheduler is running!' ? handleStop : handleStart}
                                            >
                                                {startStopButtonText}
                                            </Button>

                                            <Button
                                                color="error"
                                                variant="contained"
                                                sx={{
                                                    width: '45%',
                                                    fontSize: '1.5vh',
                                                    fontWeight: '600',
                                                    height: 'fit-content',
                                                    margin: "2px"
                                                }}
                                                onClick={() => { refetch2() }}
                                            >
                                               {isLoading2 ? ( // If loading is true, show CircularProgress
                                                <CircularProgress size="24px" color="success" />
                                            ) :"ping"}
                                            </Button>

                                        </TableCell>
                                    </TableRow>)))

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {/* </div> */}
        </div>

    );
};


