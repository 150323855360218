import { toast } from "react-toastify";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { deleteReddit, editUrgentReddit } from "../../api/reddit";
import "./styles.css";


export const RedditTable = (props) => {
  const { data, refetch, page, perPage } = props
  const notify = () => {
    toast.success("Deleted successfully");
  };

  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const { mutate: deleteReddit2 } = useMutation(deleteReddit, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify();
        refetch();
      }
    },
  });

  const { mutate: editUrgentReddit2 } = useMutation(editUrgentReddit, {
    onSuccess: (res) => {
      if (res.status === 200);
      refetch();
    },
  });

  const handleDelete = (channelId) => {
    deleteReddit2(channelId);
  };

  if (!data || data === undefined) {
    return null;
  }
  // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <div className="thread_container">
        <div className="reddit_table_header">
          <p style={{ maxWidth: "50px" }}>S.No.</p>
          <p>Subreddit ID</p>
          <p>Name</p>
          <p>Description</p>
          <p>No Of Subscribers</p>
          <p>Status</p>
          <p>Time</p>
          <p>Failed Count</p>
          <p>Actions</p>
        </div>
        {data === undefined && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="success" />
          </div>
        )}
        <div className="reddit_table_body">
          {data?.map((item, i) => (
            <div className="reddit_table_row" key={item?._id?.$oid}>
              <div className={"forum_table_columns"} style={{ maxWidth: "50px" }}>
                <p>{(page * perPage) + i + 1}</p>
              </div>
              <div className={"reddit_table_columns"}>
                <p>{item?.subredditID}</p>
              </div>
              <Tooltip title={item?.site} placement="bottom-start">
                <div className={"reddit_table_columns"}>
                  <p>{item?.Name}</p>
                </div>
              </Tooltip>
              <div className={"reddit_table_columns"}>
                <p>{item?.description}</p>
              </div>
              <div className={"reddit_table_columns"}>
                <p>{item?.noOfSubscribers.toString()}</p>
              </div>
              <div className={"reddit_table_columns"}>
                <p>{item?.status}</p>
              </div>{" "}
              <div className={"reddit_table_columns"}>
                <p>{new Date(item?.time?.$date).toLocaleString()}</p>
              </div>
              <div className={"reddit_table_columns"}>
                <p>{item?.failedCount}</p>
              </div>
              <div className={"reddit_table_columns"}>
                <div
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    color={item?.isUrgent ? "success" : "primary"}
                    sx={{
                      width: "45%",
                      fontSize: "1.5vh",
                      fontWeight: "600",
                      height: "fit-content",
                    }}
                    onClick={() => {
                      // console.log("edit button");
                      editUrgentReddit2({
                        isUrgent: !item?.isUrgent,
                        id: item?._id?.$oid,
                      })
                    }}
                  >
                    {item?.isUrgent ? "Unmark" : "Mark"}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{
                      width: "45%",
                      fontSize: "1.5vh",
                      fontWeight: "600",
                      height: "fit-content",
                    }}
                    onClick={() => handleDelete(item?._id?.$oid)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
