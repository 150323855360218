import CloseIcon from "@mui/icons-material/Close";
import { Delete, Add } from "@mui/icons-material";

import {
  Select,
  Button,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { posti2p, puti2p } from "../../api/i2p";

import "../../styles/login.css";
import { useEffect, useState } from "react";
import { Pagination, Box, Stepper, Step, StepLabel } from '@mui/material';

const steps = [
  'Website Information',
  'Thread Link Extraction Information',
  'Thread Scraping Information'
];

export const I2PForm = (props) => {
  const { setOpen, editData, setEditData, refetch } = props;

  const [state, setState] = useState(0);
  const [Site, setSite] = useState("");
  const [Name, setName] = useState("");
  const [UrlPath, setUrlPath] = useState(["", ""]);
  const [DatePath, setDatePath] = useState(["", ""]);
  const [BodyPath, setBodyPath] = useState([["", ""]]);
  const [TitlePath, setTitlePath] = useState(["", ""]);
  const [MediaPath, setMediaPath] = useState([["", ""]]);
  const [AuthorPath, setAuthorPath] = useState(["", ""]);
  const [LastModPath, setLastModPath] = useState(["", ""]);
  const [NextBtnPath, setNextBtnPath] = useState(["", ""]);
  const [SectionPath, setSectionPath] = useState([["", ""]]);
  const [IteratorPath, setIteratorPath] = useState(["", ""]);
  const [ExpandBtnPath, setExpandBtnPath] = useState(["", ""]);
  const [ProfileLinkPath, setProfileLinkPath] = useState(["", ""]);
  const [SectionNextBtnPath, setSectionNextBtnPath] = useState(["", ""]);

  function fixForNull(value) {
    if(value[1] === "") {
      return [null, null];
    }
    return value;
  }

  function fixForNullAOA(value) {
    if(value.length === 0){
      return [[null, null]];
    }
    value.forEach((item) => {
      if(value[1] === "") {
        return [null, null];
      }
    });
    return value;
  }

  useEffect(() => {
    if (!editData?.site) return;
    setName(editData?.name || "__");
    setSite(editData?.site || "__");
    setUrlPath(editData?.urlPath || UrlPath);
    setDatePath(editData?.date_path || DatePath);
    setBodyPath(editData?.body_path || BodyPath);
    setTitlePath(editData?.title_path || TitlePath);
    setMediaPath(editData?.media_path || MediaPath);
    setLastModPath(editData?.lastModPath || LastModPath);
    setSectionPath(editData?.sectionPath || SectionPath);
    setExpandBtnPath(editData?.expand_btn || ExpandBtnPath);
    setIteratorPath(editData?.iterator_path || IteratorPath);
    setAuthorPath(editData?.author_name_path || AuthorPath);
    setNextBtnPath(editData?.path_of_next_btn || NextBtnPath);
    setProfileLinkPath(editData?.profile_link_path || ProfileLinkPath);
    setSectionNextBtnPath(
      editData?.path_of_sectionNext_btn || SectionNextBtnPath
    );
  }, [editData]);

  const resetForm = () => {
    setSite("");
    setName("");
    setUrlPath(["", ""]);
    setDatePath(["", ""]);
    setTitlePath(["", ""]);
    setSectionPath(["", ""]);
    setLastModPath(["", ""]);
    setAuthorPath(["", ""]);
    setNextBtnPath(["", ""]);
    setBodyPath([["", ""]]);
    setMediaPath([["", ""]]);
    setIteratorPath(["", ""]);
    setProfileLinkPath(["", ""]);
    setExpandBtnPath(["", ""]);
    setSectionNextBtnPath(["", ""]);
  };

  useEffect(() => {}, [editData]);

  const notify = (type) => {
    toast.success(`${type} successfull`);
  };

  const { mutate: post_forum } = useMutation(posti2p, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify("Uploaded");
        refetch();
        resetForm();
      }
    },
  });
  const { mutate: edit_forum } = useMutation(puti2p, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify("Updated");
        refetch();
        resetForm();
        setEditData({});
      }
    },
  });
  const handleSave = () => {
    const obj = {
      name: Name,
      site: Site,
      urlPath: fixForNull(UrlPath),
      date_path: fixForNull(DatePath),
      body_path: fixForNullAOA(BodyPath),
      media_path: fixForNullAOA(MediaPath),
      title_path: fixForNull(TitlePath),
      sectionPath: fixForNullAOA(SectionPath),
      lastModPath: fixForNull(LastModPath),
      expand_btn: fixForNull(ExpandBtnPath),
      iterator_path: fixForNull(IteratorPath),
      author_name_path: fixForNull(AuthorPath),
      path_of_next_btn: fixForNull(NextBtnPath),
      profile_link_path: fixForNull(ProfileLinkPath),
      path_of_sectionNext_btn: fixForNull(SectionNextBtnPath),
    };
    if (editData?._id) {
      edit_forum({ ...obj, id: editData?._id?.$oid });
      return;
    }
    post_forum(obj);
  };

  const deleteFromArray = (state, index) => {
    if (state === "SectionPath") {
      setSectionPath((oldSP) => oldSP.filter((s, i) => i != index));
    }
    if (state === "BodyPath") {
      setBodyPath((oldSP) => oldSP.filter((s, i) => i != index));
    }
    if (state === "MediaPath") {
      setMediaPath((oldSP) => oldSP.filter((s, i) => i != index));
    }
    // if (state === "ExpandBtnPath") {
    //   setExpandBtnPath((oldSP) => oldSP.filter((s, i) => i != index));
    // }
  };

  const addToArray = (state) => {
    if (state === "SectionPath") {
      setSectionPath((oldSP) => [...oldSP, ["", ""]]);
    }
    if (state === "BodyPath") {
      setBodyPath((oldSP) => [...oldSP, ["", ""]]);
    }
    if (state === "MediaPath") {
      setMediaPath((oldSP) => [...oldSP, ["", ""]]);
    }
    // if (state === "ExpandBtnPath") {
    //   setExpandBtnPath((oldSP) => [...oldSP, ["", ""]]);
    // }
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeSite = (e) => {
    setSite(e.target.value);
  };

  const handleArrayPath = (e, i, state) => {
    if (state === "UrlPath") {
      const updatedPaths = [...UrlPath];
      updatedPaths[i] = e.target.value;
      setUrlPath(updatedPaths);
    }
    if (state === "LastModPath") {
      const updatedPaths = [...LastModPath];
      updatedPaths[i] = e.target.value;
      setLastModPath(updatedPaths);
    }
    if (state === "SectionNextBtnPath") {
      const updatedPaths = [...SectionNextBtnPath];
      updatedPaths[i] = e.target.value;
      setSectionNextBtnPath(updatedPaths);
    }
    if (state === "TitlePath") {
      const updatedPaths = [...TitlePath];
      updatedPaths[i] = e.target.value;
      setTitlePath(updatedPaths);
    }
    if (state === "IteratorPath") {
      const updatedPaths = [...IteratorPath];
      updatedPaths[i] = e.target.value;
      setIteratorPath(updatedPaths);
    }
    if (state === "AuthorPath") {
      const updatedPaths = [...AuthorPath];
      updatedPaths[i] = e.target.value;
      setAuthorPath(updatedPaths);
    }
    if (state === "ProfileLinkPath") {
      const updatedPaths = [...ProfileLinkPath];
      updatedPaths[i] = e.target.value;
      setProfileLinkPath(updatedPaths);
    }
    if (state === "DatePath") {
      const updatedPaths = [...DatePath];
      updatedPaths[i] = e.target.value;
      setDatePath(updatedPaths);
    }
    if (state === "NextBtnPath") {
      const updatedPaths = [...NextBtnPath];
      updatedPaths[i] = e.target.value;
      setNextBtnPath(updatedPaths);
    }
    if (state === "ExpandBtnPath") {
      const updatedPaths = [...ExpandBtnPath];
      updatedPaths[i] = e.target.value;
      setExpandBtnPath(updatedPaths);
    }
  };

  const handleArray2Path = (e, index, i, state) => {
    if (state === "SectionPath") {
      const updatedPaths = [...SectionPath];
      updatedPaths[index][i] = e.target.value;
      setSectionPath(updatedPaths);
    }
    if (state === "BodyPath") {
      const updatedPaths = [...BodyPath];
      updatedPaths[index][i] = e.target.value;
      setBodyPath(updatedPaths);
    }
    if (state === "MediaPath") {
      const updatedPaths = [...MediaPath];
      updatedPaths[index][i] = e.target.value;
      setMediaPath(updatedPaths);
    }
    // if (state === "ExpandBtnPath") {
    //   const updatedPaths = [...ExpandBtnPath];
    //   updatedPaths[index][i] = e.target.value;
    //   setExpandBtnPath(updatedPaths);
    // }
  };
  return (
    <>
      <div className="form_modal_scraping">
        <div className="form_modal_scraping_close_icon" onClick={handleClose}>
          <CloseIcon />
        </div>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={state} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {state === 0 && <div className="scraping_input_container">
          <TextField
            required
            type="text"
            label="Name"
            value={Name}
            variant="standard"
            placeholder="Name&#42;"
            className="scraping_input"
            onChange={handleChangeName}
          />
          <TextField
            required
            type="text"
            label="Site URL"
            value={Site}
            variant="standard"
            placeholder="Site URL&#42;"
            className="scraping_input"
            onChange={handleChangeSite}
          />
        </div>}
        {state === 1 && <>
        <p className="grouped_label">Link Extraction Information</p>
        <div className="grouped_inputs">
          <div className="scraping_input_container_with_menu">
            <div>
              <div style={{ display: "flex" }}>
                <h3>Section Paths</h3>
                <Button
                  color="primary"
                  onClick={() => addToArray("SectionPath")}
                >
                  <Add />
                </Button>
              </div>
              {SectionPath.map((value, index) => (
                <div key={index}>
                  <Array2FormComponent
                    index={index}
                    state={SectionPath}
                    stateName="SectionPath"
                    onDelete={deleteFromArray}
                    changeHandle={handleArray2Path}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="scraping_input_container">
            <div>
              <label>URL Path</label>
              <ArrayFormComponent
                state={UrlPath}
                stateName="UrlPath"
                changeHandle={handleArrayPath}
              />
            </div>
          </div>

          <div className="scraping_input_container">
            <div>
              <label>Last Modified Date Path</label>
              <ArrayFormComponent
                stateName="LastModPath"
                state={LastModPath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
          <div className="scraping_input_container">
            <div>
              <label>Section Next Button Path</label>
              <ArrayFormComponent
                stateName="SectionNextBtnPath"
                state={SectionNextBtnPath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
        </div>
        </>}
        {state === 2 && <>
        <p className="grouped_label">Thread Scraping Information</p>
        <div className="grouped_inputs">
          <div className="scraping_input_container">
            <div>
              <label>Title Path</label>
              <ArrayFormComponent
                stateName="TitlePath"
                state={TitlePath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
          <div className="scraping_input_container">
            <div>
              <label>Iterator Path</label>
              <ArrayFormComponent
                stateName="IteratorPath"
                state={IteratorPath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
          <div className="scraping_input_container">
            <div>
              <label>Author Name Path</label>
              <ArrayFormComponent
                stateName="AuthorPath"
                state={AuthorPath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
          <div className="scraping_input_container">
            <div className="">
              <label>Profile Link Path</label>
              <ArrayFormComponent
                stateName="ProfileLinkPath"
                state={ProfileLinkPath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
          <div className="scraping_input_container">
            <div>
              <label>Date Path</label>
              <ArrayFormComponent
                stateName="DatePath"
                state={DatePath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
          <div className="scraping_input_container">
            <div className="">
              <label>Next Button Path</label>
              <ArrayFormComponent
                stateName="NextBtnPath"
                state={NextBtnPath}
                changeHandle={handleArrayPath}
              />
            </div>
          </div>
        </div>
        <div className="scraping_input_container_with_menu">
          <div>
            <div style={{ display: "flex" }}>
              <h3>Body Paths</h3>
              <Button color="primary" onClick={() => addToArray("BodyPath")}>
                <Add />
              </Button>
            </div>
            {BodyPath.map((value, index) => (
              <div key={index}>
                <Array2FormComponent
                  index={index}
                  state={BodyPath}
                  stateName="BodyPath"
                  onDelete={deleteFromArray}
                  changeHandle={handleArray2Path}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="scraping_input_container_with_menu">
          <div>
            <div style={{ display: "flex" }}>
              <h3>Media Paths</h3>
              <Button color="primary" onClick={() => addToArray("MediaPath")}>
                <Add />
              </Button>
            </div>
            {MediaPath.map((value, index) => (
              <div key={index}>
                <Array2FormComponent
                  index={index}
                  state={MediaPath}
                  stateName="MediaPath"
                  onDelete={deleteFromArray}
                  changeHandle={handleArray2Path}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="scraping_input_container_with_menu">
          <div>
            {/* <div style={{ display: "flex" }}>
              <h3>Expand Button Paths</h3>
              <Button
                color="primary"
                onClick={() => addToArray("ExpandBtnPath")}
              >
                <Add />
              </Button>
            </div>
            {ExpandBtnPath.map((value, index) => (
              <div key={index}>
                <Array2FormComponent
                  index={index}
                  state={ExpandBtnPath}
                  stateName="ExpandBtnPath"
                  onDelete={deleteFromArray}
                  changeHandle={handleArray2Path}
                />
              </div>
            ))} */}
            <div className="scraping_input_container">
              <div className="">
                <label>Expand Button Paths</label>
                <ArrayFormComponent
                  stateName="ExpandBtnPath"
                  state={ExpandBtnPath}
                  changeHandle={handleArrayPath}
                />
              </div>
            </div>
          </div>
        </div>
        </>}
        <div className="scraping_input_container1">
          <Button onClick={() => state===0 ? handleClose() : setState(state-1) } variant="contained" color="error">
            { state === 0 ? "Close": "Prev" }
          </Button>
          <Button onClick={() => (state === steps.length - 1) ? handleSave() : setState(state + 1)} variant="contained" color="success">
            {state === steps.length - 1 ? "Save": "Next"}
          </Button>
        </div>
      </div>
    </>
  );
};

const ArrayFormComponent = (props) => {
  const { stateName, state, changeHandle } = props;

  return (
    <div>
      <Select
        value={state[0]}
        variant="standard"
        sx={{ mt: 2, mr: "2%", width: "45%" }}
        onChange={(e) => changeHandle(e, 0, stateName)}
      >
        <MenuItem value={""}>Select CSS Option</MenuItem>
        <MenuItem value={"CSS_SELECTOR"}>CSS Selector</MenuItem>
        <MenuItem value={"XPATH"}>XPATH</MenuItem>
      </Select>
      <TextField
        required
        type="text"
        value={state[1]}
        variant="standard"
        label="Selector Value"
        className="scraping_input_with_select"
        placeholder="Selector Value&#42;"
        onChange={(e) => changeHandle(e, 1, stateName)}
      />
    </div>
  );
};
const Array2FormComponent = (props) => {
  const { stateName, state, index, changeHandle, onDelete } = props;

  return (
    <div>
      <Select
        value={state[index][0]}
        variant="standard"
        sx={{ mt: 2, mr: "2%", width: "45%" }}
        onChange={(e) => changeHandle(e, index, 0, stateName)}
      >
        <MenuItem value={""}>Select CSS Option</MenuItem>
        <MenuItem value={"CSS_SELECTOR"}>CSS Selector</MenuItem>
        <MenuItem value={"XPATH"}>XPATH</MenuItem>
      </Select>
      <TextField
        required
        type="text"
        label="Selector Value"
        value={state[index][1]}
        variant="standard"
        placeholder="Selector Value&#42;"
        className="scraping_input_with_select"
        onChange={(e) => changeHandle(e, index, 1, stateName)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => onDelete(stateName, index)}>
                <Delete sx={{ color: "red" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
