import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import {
  Alert,
  Badge,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Tooltip,
} from "@mui/material";
import "./styles.css";
import { useState } from "react";
import Menu from "@mui/material/Menu";

import axios from "axios";

import { deleteHeadful, putmarkUrgentHeadful } from "../../api/headful";
import HeadfulForumsTable from "./HeadfulThreadLinkInfo";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/config";
import "react-toastify/dist/ReactToastify.css";
import { Circle } from "@mui/icons-material";

export const HeadfulTable = ({
  data,
  refetch,
  openForm,
  setEditData,
  page,
  perPage,
}) => {
  const navigate = useNavigate();
  const [selectedThread, setSelectedThread] = useState(null);
  const [loadingMap, setLoadingMap] = useState({});
  const [message, setMessage] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const statusNotify = () => toast.warning("Busy!");
  const [globalState, setGlobalState] = useState({
    globalSite: null,
    globalId: null,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectItem, setSelectItem] = useState(null);

  const handleClick1 = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownSelect = (value) => {
    handleClose();
  };
  const handleClick = async (item) => {
    const headfulData = {
      name: item?.name,
      url: item?.site,
      waitTime: 300,
      siteType:"forum"
    };
    try {
      const firstApiResponse = await axios.get(
        "https://headapi.emsec.dev/status"
      );
      console.log(firstApiResponse.data, "1");
      if (firstApiResponse.data.status === "busy") {
        statusNotify();
      }

      if (firstApiResponse.data.status === "free") {
        const secondApiResponse = await axios.post(
          "https://headapi.emsec.dev/startscrapping",
          headfulData
        );
        navigate("/headful", { replace: true });

        console.log("Response from the second API:", secondApiResponse);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleClosethread = () => {
    setSelectedThread(null);
  };

  // ---------------------------------------------------------------------------------------------------------
  const notify = (type) => {
    toast.success(`${type}`, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const notifyAlert = (msg) => {
    toast.warning(msg, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // console.log("========>", data)
  // ------------------------------------------------------------------------------------------------------------
  const { mutate: deleteId } = useMutation(deleteHeadful, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify("Deleted successfully");
        refetch();
      }
    },
  });

  const handleDelete = (id) => {
    deleteId(id);
  };
  const { mutate: markUrgentHeadful } = useMutation(putmarkUrgentHeadful, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        console.log("marked",selectItem?.isUrgent)
        notify(`${!selectItem?.isUrgent?"Marked":"Unmark"} successfully`);
        refetch();
      }
    },
  });

  // putmarkUrgentHeadful
  // --------------------------------------------------------------------------------------------------------

  const handleSelectUrl = async (site, id, page) => {
    console.log(site, id, page);
    setLoadingMap((prev) => ({ ...prev, [id]: true }));
    try {
      console.log(site, id, page);
      const response = await axios.post(`${BASE_URL}/forum_url_data`, {
        site: site,
        page: page,
      });

      const results = response.data;
      // console.log(results)

      if (results.status === "success") {
        setSelectedThread(results?.data);
      } else {
        notifyAlert(results?.msg);
        // console.log("error")
      }
    } catch (error) {
      console.error("Error fetching channel info:=========>", error);
    } finally {
      setLoadingMap((prev) => ({ ...prev, [id]: false }));
    }
  };
  //------------------------------------------------------------------------------------------------
  const refreshForurgen = async (site) => {
    try {
      const response = await axios.post(`${BASE_URL}/refreshForurgent`, {
        site: site, 
      });

      if (response.data.status === 'success') {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while fetching data.');
    } finally {
      notify(message?.msg)
    }
  };
  // ------------------------------------------------------------------------------------------------
  const handleMarkAllUrgent=async(site,id)=>{
    console.log("mark all",site,id)
    try {
      const response = await axios.post(`${BASE_URL}/markAllurgent`, {
        site: site,
        _isUrgent: true
      });

      const results = response.data;
      if (results.status === "success") {
        // setIsMark(true);
        notify(results?.msg)
      } else {
        notifyAlert(results?.msg)

      }
    } catch (error) {
      console.error('Error fetching channel info:=========>', error);
      notifyAlert('Error fetching channel info')
    } finally {
      // refreshForurgen(site)
    }

  
  }
//-------------------------------------------------------------------------------------------
  if (!data) {
    return null;
  }

  return (
    <>
      <div className="thread_container">
        {/* {loading? <CircularProgress justifyContent="center" size="20px" color="success" />:""} */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
          // message={message?.status} 
          ><Alert severity={message?.status}>{message?.msg}</Alert>
          </Snackbar>
        <div className="forum_table_header" style={{ padding:0 }} >
          <p >S.No.</p>
          <p style={{ minWidth: "10%" }}>Name</p>
          <p style={{ minWidth: "15%" }}>URL</p>
          <p style={{ minWidth: "10%" }}>Status</p>
          <p style={{ minWidth: "10%" }}>Failed Count</p>
          
          <p style={{ minWidth: 150 }}>Actions</p>
        </div>
        {data === undefined && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="success" />
          </div>
        )}
        <div className="forum_table_body">
          {data?.map((item, index) => (
            <div className="forum_table_row" key={item?._id?.$oid}>
              <div
                className={"forum_table_columns"}
                
              >
                <p>{page * perPage + index + 1}</p>
              </div>
              <div className={"forum_table_columns"} style={{ minWidth: "10%",paddingLeft:0,paddingRight:0}}>
                <p>{item?.name || "___"}</p>
              </div>
              <Tooltip title={item?.site} placement="bottom-start">
                <div className={"forum_table_columns"} style={{ minWidth: "15%" ,paddingLeft:0,paddingRight:0}}>
                  <p>{item?.site}</p>
                </div>
              </Tooltip>

              <div className={"forum_table_columns"} style={{ minWidth: "10%",paddingLeft:0,paddingRight:0}}>
                <p>{item?.status}</p>
              </div>

              <div className={"forum_table_columns"} style={{ minWidth: "10%",paddingLeft:0,paddingRight:0}}>
                <p>{item?.failedCount}</p>
              </div>
              

              <div className={"forum_table_columns"} style={{ display:"flex",justifyContent:"space-between",minWidth: "10%",paddingLeft:0,paddingRight:0}}>
                <div
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick1(e, item)}
                    color="primary"
                    variant="contained"
                    sx={{
                      height: "fit-content",
                      fontSize: "1.5vh",
                      fontWeight: "600",
                    }}
                  >
                    {loadingMap[item?._id?.$oid] ? "Loading.." : "Actions"}
                  </Button>
                  {<Badge color={item?.siteStatus==="up"?'success':"error"} variant="dot"/>}
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setGlobalState({
                          ...globalState,
                          globalSite: selectItem?.site,
                          globalId: selectItem?._id?.$oid,
                        });
                        handleSelectUrl(
                          selectItem?.site,
                          selectItem?._id?.$oid,
                          1
                        );
                      }}
                    >
                      View
                    </MenuItem>


                    <MenuItem
                      onClick={() => {
                       
                        markUrgentHeadful({
                          isUrgent: !selectItem?.isUrgent,
                          id: selectItem?._id?.$oid
                        })
                      }}
                    >
                      {selectItem?.isUrgent ? "Unmark" : "Mark urgent"}
                    </MenuItem>


                    <MenuItem
                      onClick={() => {
                        handleClose();
                        openForm();
                        setEditData(selectItem);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleDelete(selectItem?._id?.$oid);
                      }}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleClick(selectItem);
                      }}
                    >
                      Headful Scraping
                    </MenuItem>
                  </Menu>
                </div>
               
                
              </div>
            </div>
          ))}
        </div>
        {selectedThread !== null && (
          <HeadfulForumsTable
            selectedThread={JSON.parse(selectedThread)}
            handleSelectUrl={handleSelectUrl}
            handleClosethread={handleClosethread}
            globalState={globalState}
            notify={notify}
            notifyAlert={notifyAlert}
          />
        )}
      </div>
    </>
  );
};
