import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { deleteTelegram, editUrgentTelegram } from "../../api/telegram";
import "./styles.css";

export const TelegramTable = ({ data, refetch, openForm, setEditData, page, perPage }) => {
  const notify = () => {
    toast.success("Deleted successfully");
  };
  const { mutate: deleteId } = useMutation(deleteTelegram, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify();
        refetch();
      }
    },
  });

  const { mutate: editMark } = useMutation(editUrgentTelegram, {
    onSuccess: (res) => {
      if (res.status === 200) refetch();
    },
  });

  const handleDelete = (id) => {
    deleteId(id);
  };

  if (!data || data === undefined) {
    return null;
  }

  return (
    <>
      <div className="thread_container">
        <div className="telegram_table_header">
          <p style={{maxWidth: "50px"}}>S.No.</p>
          <p>Channel ID</p>
          <p>Channel Name</p>
          <p>Status</p>
          <p>Time</p>
          <p>Actions</p>
        </div>
        {data === undefined && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="success" />
          </div>
        )}
        <div className="telegram_table_body">
          {data?.map((item, i) => (
            <div className="telegram_table_row" key={item?._id?.$oid}>
              <div className={"forum_table_columns"} style={{maxWidth: "50px"}}>
                <p>{(page*perPage)+i+1}</p>
              </div>
              <div className={"telegram_table_columns"}>
                <p>{item?.channel_id}</p>
              </div>
              <Tooltip title={item?.site} placement="bottom-start">
                <div className={"telegram_table_columns"}>
                  <p>{item?.channel_name}</p>
                </div>
              </Tooltip>
              <div className={"telegram_table_columns"}>
                <p>{item?.status}</p>
              </div>{" "}
              <div className={"telegram_table_columns"}>
                <p>{new Date(item?.time?.$date).toLocaleString()}</p>
              </div>
              <div className={"telegram_table_columns"}>
                <div
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    color={item?.isUrgent ? "success" : "primary"}
                    sx={{
                      width: "45%",
                      fontSize: "1.5vh",
                      fontWeight: "600",
                      height: "fit-content",
                    }}
                    onClick={() => {
                      editMark({
                        isUrgent: !item?.isUrgent,
                        id: item?._id?.$oid,
                      });
                    }}
                  >
                    {item?.isUrgent ? "Unmark" : "Mark"}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{
                      width: "45%",
                      fontSize: "1.5vh",
                      fontWeight: "600",
                      height: "fit-content",
                    }}
                    onClick={() => handleDelete(item?._id?.$oid)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
