import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { Button, TextField, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../components/discord/styles.css";
import { BASE_URL_THREADS } from '../../utils/config';
// import { BASE_URL_Thread } from '../../utils/config';


const Thread_form = (props) => {
    const { handleClose, refetch } = props;
    const [profileUrl, setprofileUrl] = useState("");
    const [alertmsg, setAlertMsg] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState(null);
    // const [selectedIds, setSelectedIds] = useState([]);





    const handleGetProfileInfo = async (event) => {
        event.preventDefault();
        if (
            profileUrl.trim() === ''
        ) {
            setAlertMsg({status:"warning",msg:"Please fill the box before getting the Profile info."})
            setTimeout(() => {
                setAlertMsg(null);
            }, 5000);
        } else {
            try {
                // const response = await axios.post(`${BASE_URL_REDDIT}/getSubredditData`, {
                const response = await axios.post(`${BASE_URL_THREADS}/getThreadsProfileInfo`, {
                profileUrl:profileUrl ,

                });
                const results= response.data;
                console.log(results.status, results.msg, results.result)
                if (results.status === "success") {
                    setSelectedProfile(results.result);
                    setAlertMsg(results)
                    setTimeout(() => {
                        setAlertMsg(null);

                    }, 5000);
                } else {
                    setAlertMsg(results)
                    setTimeout(() => {
                        setAlertMsg(null);

                    }, 5000);

                }
            } catch (error) {
                console.error('Error fetching Profile info:', error);
                setAlertMsg({status:"error",msg:"Network Error"})
                setTimeout(() => {
                    setAlertMsg(null);
                }, 5000);
            }
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${BASE_URL_THREADS}/addToThreadsDb`);
            const results = response.data;
            console.log(results.msg)

            setAlertMsg(results)
           
            setTimeout(() => {
                setAlertMsg(null);
                // handleClose()
                setSelectedProfile(null)
            }, 5000);
            // refetch()


            ;
        } catch (error) {
            console.error('Error fetching selected Profile info:', error);
            setAlertMsg({status:"error",msg:"Network Error"})
        }
        refetch()
        
    };

   
    return (
        <div className="form_modal_scraping">
            <div
                className="form_modal_scraping_close_icon"
                onClick={() => {
                    handleClose();
                }}
            >
                <CloseIcon />
            </div>

            <h1>Get Profile Info</h1>

            <div className="scraping_input_container">
                {alertmsg !== null && (<Alert severity={alertmsg.status}>{alertmsg.msg}</Alert>)}
                <TextField
                    type="text"
                    placeholder="Enter Thread Profile Url"
                    value={profileUrl}
                    onChange={(e) => setprofileUrl(e.target.value)}
                />
                <Button color="primary"
                    variant="contained"
                    sx={{
                        width: "45%",
                        fontSize: "1.5vh",
                        fontWeight: "600",
                        height: "fit-content",
                    }}
                    onClick={handleGetProfileInfo}>Get Profile Info
                </Button>
            </div>


            {selectedProfile !== null &&
                <div>
                    <div className="scraping_input_container" style={{ border: '1px solid #dddddd', marginTop: "15px" }}>
                        
                        <div style={{ padding: "5px" }}>
                            <p><strong style={{ color: "green" }}>Full Name:</strong> {selectedProfile.fullName}</p>
                            <p><strong style={{ color: "green" }}>Insta Id:</strong> {selectedProfile.instaId}</p>
                            <p><strong style={{ color: "green" }}>Bio:</strong> {selectedProfile.bio}</p>
                            <p><strong style={{ color: "green" }}>UserName:</strong> {selectedProfile.username }</p>
                            
                        </div>
               
                    </div>
                    <div style={{marginTop: "15px"}}>
                        <Button onClick={handleClose} variant="contained" color="error">
                            Cancel
                        </Button>
                        <Button variant="contained" color="success" onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>}


        </div>
    );
};

export default Thread_form;
