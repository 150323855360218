import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Pagination,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { BASE_URL } from "../../utils/config";
import { toast } from "react-toastify";

function LinkValidation() {
  const [data, setData] = useState([]);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(50);
  const [totalCount, settotalCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [searchWord, setSearchWord] = useState('');

const notify = (type) => {
    toast.success(`${type}`);
}; 
const notifyError = (type) => {
  toast.error(`${type}`);
};
//------------------------------------------------------------------------------------------------
  function loadData() {
    axios
      .get(`${BASE_URL}/url_assets?page=${page}&limit=${limit}`)
      // .get(`http://127.0.0.1:5004/url_assets?page=${page}&limit=${limit}`)
      .then((res) => {
        const arr = res.data.list;
        arr.sort((a, b) => parseFloat(b.date) - parseFloat(a.date));
        setData(arr);
        settotalCount(res.data.count);
        // if (searchWord !== '') {
          applyFilter(arr)
        // };
      });
  }
  //------------------------------------------------------------------------------------------------
  const applyFilter = (dataToFilter) => {
    const newFilter = dataToFilter.filter((value) => {
      return value.domain_name.toLowerCase().includes(searchWord.toLowerCase());
    });

    const sorted_arr = newFilter.sort((a, b) => parseFloat(b.date) - parseFloat(a.date));
    setFilteredData(sorted_arr);
  }

//------------------------------------------------------------------------------------------------
  const handleInvalid = (item) => {
    axios
      .put(`${BASE_URL}/url_assets`, { id: item.id, valid: false })
      // .put(`http://127.0.0.1:5004/url_assets`, { id: item.id, valid: false })
      .then((res) => {
        if (res.data.status === 200) {
          // alert
          notify("Record marked as invalid");
        } else {
          notifyError("Some error occured");
        }

        loadData();
      });
  };
//------------------------------------------------------------------------------------------------
useEffect(() => {
  loadData();
}, [page, searchWord]);

useEffect(() => {
  applyFilter(data);
}, [searchWord]);
//---------------------------------------------------------------------------------------------------
  return (
    <div className="login_container">
      <h2>Link Validation</h2>
      <TextField
        type="text"
        className="input"
        sx={{ mb: "8px" }}
        name="Username"
        onChange={(e) => setSearchWord(e.target.value)}
        variant="outlined"
        placeholder="search..."
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />


      <Paper
        sx={{

          overflow: "hidden",
        }}
      >
        <TableContainer
          sx={{
            width: "95vw",
            height: "90vh",
            backgroundColor: "rgba(0,0,0,0.4)",
            overflowY: "auto",
          }}
        >
          <Table stickyHeader >
            <TableHead sx={{ color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell style={{ width: "10%" }}>S.No.</TableCell>
                <TableCell style={{ width: "50%" }}>Domain Name</TableCell>
                <TableCell style={{ width: "20%" }}>Date</TableCell>
                <TableCell style={{ width: "20%" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            {filteredData.length === 0 && <TableBody sx={{ color: "white" }}>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: "10%" }}>
                    {(page - 1) * limit + index + 1}
                  </TableCell>
                  <TableCell style={{ minWidth: "50%" }}>
                    {item.domain_name}
                  </TableCell>
                  <TableCell style={{ minWidth: "20%" }}>
                    {new Date(item.date * 1000).toLocaleString()}
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(evt) => handleInvalid(item)}
                    >
                      Mark as Invalid
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>}
            {filteredData.length !== 0 && <TableBody sx={{ color: "white" }}>
              {filteredData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell style={{ width: "10%" }}>
                    {(page - 1) * limit + index + 1}
                  </TableCell>
                  <TableCell style={{ minWidth: "50%" }}>
                    {item.domain_name}
                  </TableCell>
                  <TableCell style={{ minWidth: "20%" }}>
                    {new Date(item.date * 1000).toLocaleString()}
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(evt) => handleInvalid(item)}
                    >
                      Mark as Invalid
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>}
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        page={page}
        sx={{ mt: "15px", mb: "20px" }}
        size="large"
        count={Math.ceil(totalCount / limit)}
        color="secondary"
        variant="outline"
        shape="rounded"
        onChange={(event, value) => setpage(value)}
      />
    </div>
  );
}

export default LinkValidation;
