import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useQuery } from '@tanstack/react-query';

// import { getThread } from '../../api/reddit';

import "../../components/discord/styles.css";
import { AlternateEmail } from '@mui/icons-material';
import Thread_form from '../thread/form';
import { getReddit } from '../../api/reddit';
import { ThreadTable } from '../thread/table';
import { getThreads } from '../../api/threads';
// import { ThreadTable } from '../thread/table';

const ScrapingEngineThread = () => {
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [TotalCount, setTotalCount] = useState(0);



    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseAuth = () => {
        setOpen2(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const { data, refetch, isLoading } = useQuery({
        queryKey: ["credentails"],
        queryFn: () => getThreads(),
    });
    console.log(data)
    console.log(Math.ceil(JSON.parse(data || "[]").length/ rowsPerPage))

    useEffect(() => {
        setTotalCount(Math.ceil(JSON.parse(data || "[]").length / rowsPerPage));
    }, [data])

    return (
        <div className="login_container">
            <AlternateEmail sx={{ fontSize: 40 }} />

            <div className="AddButton" >

                {/* <Button
                    variant="contained"
                    onClick={handleClickOpen2}
                    sx={{
                        width: "25vh",
                        height: "5vh",
                        fontSize: "2vh",
                        fontWeight: "600",
                        backgroundColor: "#FC3B93",
                    }}
                >
                    Add auth key
                </Button> */}
                <Button
                    variant="contained"
                    onClick={handleClickOpen}
                    sx={{
                        width: "25vh",
                        height: "5vh",
                        fontSize: "2vh",
                        fontWeight: "600",
                        backgroundColor: "#FC3B93",
                    }}
                >
                    Add profile
                </Button>
            </div>
            {open &&
                <Thread_form
                    handleClose={handleClose}
                    refetch={refetch}
                />}



            <ThreadTable
                data={
                    
                    data !== undefined
                        ? JSON.parse(data).slice(
                            page * rowsPerPage,
                            rowsPerPage + page * rowsPerPage
                        )
                        : []
                }
                refetch={refetch}
                page={page}
                perPage={rowsPerPage}
            />
            <div>
                
                { <Pagination
                    page={page + 1}
                    size="large"
                    count={TotalCount}
                    color="secondary"
                    variant='outline'
                    shape='rounded'
                    onChange={(event, value) => setPage(value - 1)}
                /> }
            </div>  

        </div>
    );
};

export default ScrapingEngineThread;
