import { BASE_URL } from "../utils/config";

export const postHeadful = async (data) => {
  return fetch(`${BASE_URL}/forum`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data }),
  });
};

export const getHeadful = async () => {
  const res = await fetch(`${BASE_URL}/forum`, {
    method: "GET",
  });
  return res.json();
};

export const deleteHeadful = async (id) => {
  return fetch(`${BASE_URL}/forum/${id}`, {
    method: "Delete",
  });
};

export const putHeadful = async (data) => {
  return fetch(`${BASE_URL}/forum/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data }),
  });
};
export const putmarkUrgentHeadful = async (data) => {
  return fetch(`${BASE_URL}/forum/${data?.id}`, {
    method: 'PATCH',
    headers: {
    'Content-Type': 'application/json',
  },
    body: JSON.stringify({ isUrgent: data?.isUrgent }),
  });
};
export const editLINKUrgentHeadful = async (data) => {
  return fetch(`${BASE_URL}/markurgentLink/${data?.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isUrgent: data?.isUrgent }),
  });
};

export const deleteUrlLink = async (id) => {
  // console.log(`${BASE_URL}/markurgentLink/${id}`)
  return fetch(`${BASE_URL}/markurgentLink/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};


