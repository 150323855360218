import React, { useState, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";

import { getCredentails } from "../../api/credentials";
import { Credentials_Form } from "../credentials/form";
import { CredentialsTable } from "../credentials/table";
import { Pagination } from "@mui/material";

import "../../components/credentials/styles.css";

export default function ListTable() {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [TotalCount, setTotalCount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data, status, refetch, ...result } = useInfiniteQuery({
    queryKey: ["credentails", { page: page + 1, limit: rowsPerPage }],
    queryFn: ({ queryKey }) => getCredentails(queryKey[1]),
  });

  useEffect(() => {
    if(data) {
      setTotalCount(Math.ceil(data.pages[0].count / rowsPerPage));
    }
  }, [data])

  return (
    <div className="login_container">
      <div className="AddButton">
        <Button
          variant="contained"
          sx={{
            width: "10vh",
            height: "5vh",
            fontSize: "2vh",
            fontWeight: "600",
            backgroundColor: "#FC3B93",
          }}
          onClick={handleClickOpen}
        >
          Add
        </Button>

        {open && (
          <Credentials_Form
            refetch={refetch}
            handleClose={handleClose}
            data_to_edit={editData}
            setEditData={setEditData}
          />
        )}
      </div>
      <CredentialsTable
        data={data}
        refetch={refetch}
        setEditData={setEditData}
        openForm={handleClickOpen}
      />

      <div>
        {/* <TablePagination
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={Math.round(data?.pages[0].count / rowsPerPage)}
        /> */}
        <Pagination
          page={page + 1}
          size="large"
          count={TotalCount}
          color="secondary"
          variant="outline"
          shape="rounded"
          onChange={(event, value) => setPage(value - 1)}
        />
      </div>
    </div>
  );
}
