import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { deleteForum, forum_link_data } from "../../api/forum";
import "./styles.css";
import { useState } from "react";
import I2PForumsTable from "./I2P_thread_link_info";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../utils/config";
import { deletei2p } from "../../api/i2p";
export const I2PTable = ({ data, refetch, openForm, setEditData, page, perPage }) => {
  const [selectedThread, setSelectedThread] = useState(null);
  const [loadingMap, setLoadingMap] = useState({});
  const [globalState, setGlobalState] = useState({
    globalSite: null,
    globalId: null,
    
  });

  const handleClosethread = () => {
    setSelectedThread(null)
    
  };
 
  // ---------------------------------------------------------------------------------------------------------
  const notify = (type) => {
    toast.success(`${type} successfully`,{
      position: toast.POSITION.TOP_CENTER
    });
  };
  const notifyAlert = (msg) => {
    toast.warning(msg, {
      position: toast.POSITION.TOP_CENTER
    });
  };
  
  // console.log("========>", data)
// ------------------------------------------------------------------------------------------------------------
  const { mutate: deleteId } = useMutation(deletei2p, {
    onSuccess: async (res) => {
      if (res.status === 200) {
        notify("deleted");
        refetch();
      }
    },
  });

  const handleDelete = (id) => {
    deleteId(id);
  };
// --------------------------------------------------------------------------------------------------------


  const handleSelectUrl = async (site, id, page) => {
    setLoadingMap(prev => ({ ...prev, [id]: true }));
    try {
      const response = await axios.post(`${BASE_URL}/i2p_url_data`, {
        site: site,
        page: page
      });

      const results = response.data;
      // console.log(results)

      if (results.status === "success") {
        setSelectedThread(results?.data);
      } else {
        notifyAlert(results?.msg)
        // console.log("error")
      }
    } catch (error) {
      console.error('Error fetching channel info:=========>', error);
    }
    finally {
      setLoadingMap(prev => ({ ...prev, [id]: false }));
    }
  };
// ------------------------------------------------------------------------------------------------
  

  if (!data) {
    return null;
  }


  return (
    <>
      <div className="thread_container">
        {/* {loading? <CircularProgress justifyContent="center" size="20px" color="success" />:""} */}
        <div className="forum_table_header">
          <p style={{ maxWidth: "30px" }}>S.No.</p>
          <p>Name</p>
          <p>URL</p>
          <p>Status</p>
          <p>Failed Count</p>
          <p>Actions</p>
        </div>
        {data === undefined && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="success" />
          </div>
        )}
        <div className="forum_table_body">
          {data?.map((item, index) => (
            <div className="forum_table_row" key={item?._id?.$oid}>
              <div className={"forum_table_columns"} style={{ maxWidth: "30px" }}>
                <p>{(page * perPage) + index + 1}</p>
              </div>
              <div className={"forum_table_columns"}>
                <p>{item?.name || "___"}</p>
              </div>
              <Tooltip title={item?.site} placement="bottom-start">
                <div className={"forum_table_columns"}>
                  <p>{item?.site}</p>
                </div>
              </Tooltip>

              <div className={"forum_table_columns"}>
                <p>{item?.status}</p>
              </div>

              <div className={"forum_table_columns"}>
                <p>{item?.failedCount}</p>
              </div>

              <div className={"forum_table_columns"}>
                <div
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Button
                    color="success"
                    variant="contained"
                    sx={{
                      height: "fit-content",

                      fontSize: "1.5vh",
                      fontWeight: "600",
                    }}
                    onClick={() =>{setGlobalState({
                      ...globalState,
                      globalSite: item?.site,
                      globalId: item?._id?.$oid,
                      
                    });
                      handleSelectUrl(item?.site, item?._id?.$oid, 1)}}
                   
                  >

                    {loadingMap[item?._id?.$oid] ? (
                      <CircularProgress size="24px" color="primary" />
                    ) : (
                      "View"
                    )}

                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      height: "fit-content",
                      fontSize: "1.5vh",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      openForm();
                      setEditData(item);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{
                      height: "fit-content",

                      fontSize: "1.5vh",
                      fontWeight: "600",
                    }}
                  onClick={() => handleDelete(item?._id?.$oid)}
                  >
                    Delete
                  </Button>

                </div>
              </div>
            </div>
          ))}
        </div>
        {selectedThread !== null && (<I2PForumsTable
          selectedThread={JSON.parse(selectedThread)}
          handleSelectUrl={ handleSelectUrl}
          handleClosethread={handleClosethread}
          globalState={globalState}
          notify={notify}
          notifyAlert={notifyAlert}
          

        />

        )}
      </div>
    </>
  );
};
