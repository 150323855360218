
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import { DiscordServiceStatus} from '../services_status/discord_server_status';
import { TelegramServiceStatus  } from '../services_status/telegram_server_status';
import { useQuery } from '@tanstack/react-query';
import { Status_Discord } from '../../api/discord';
import { Status_Telegram } from '../../api/telegram';
import { RedditServiceStatus  } from '../services_status/reddit_server_status';
import { Status_Reddit } from '../../api/reddit';
import "../../styles/status_styles.css"
import { Status_Threads } from "../../api/threads";
import { ThreadsServiceStatus } from "../services_status/threads_server_status";
const Services_status = () => {
    const navigate = useNavigate();

    const handleGotoBack = () => {
        navigate('/Dashboard');
    };



    const { data: data1, isLoading: isLoading1, refetch: refetch1 } = useQuery(['statusDiscord'], Status_Discord);
    const { data: data2, isLoading: isLoading2, refetch: refetch2 } = useQuery(['statusTelegram'], Status_Telegram);
    const { data: data3, isLoading: isLoading3, refetch: refetch3 } = useQuery(['statusReddit'], Status_Reddit);
    const { data: data4, isLoading: isLoading4, refetch: refetch4 } = useQuery(['statusThreads'], Status_Threads);
    console.log("===data",data4)
    return (

        <>
            <div className="login_container">
                <div className="form_modal_scraping">
                    <div className="form_modal_scraping_close_icon">
                        <div onClick={handleGotoBack}>
                            <CloseIcon />
                        </div>
                    </div>
                    <h1 style={{margin:"0px"}}>STATUS OF SERVICES</h1>
                    <div className="content_section">
                        <div style={{ marginTop: "25px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3 style={{ margin: '0' }}>Discord Scraper</h3>
                            
                            <h3 style={{ margin: '0', textAlign: 'right' }}>
                                Status: {data1 !== undefined && data1.status !=='server down' ? data1.message : "Please Start Server"}
                            </h3>
                        </div>
                        <DiscordServiceStatus
                            data1={data1}
                            isLoading1={isLoading1}
                            refetch1={refetch1}
                        />


                        <div style={{ marginTop: "25px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3 style={{ margin: '0' }}>Telegram Scraper</h3>
                            <h3 style={{ margin: '0', textAlign: 'right' }}>
                                Status: {data2 !== undefined && data2.status !=='server down' ? data2?.message : "Please Start Server"}
                            </h3>
                        </div>
                        <TelegramServiceStatus 
                            data2={data2}
                            isLoading2={isLoading2}
                            refetch2={refetch2} />

                        <div style={{ marginTop: "25px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3 style={{ margin: '0' }}>Reddit Scraper</h3>
                            <h3 style={{ margin: '0', textAlign: 'right' }}>
                                Status: {data3 !== undefined && data3.status !=='server down' ? data3?.message : "Please Start Server"}
                            </h3>
                        </div>
                        <RedditServiceStatus 
                            data3={data3}
                            isLoading3={isLoading3}
                            refetch3={refetch3} />

                        <div style={{ marginTop: "25px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h3 style={{ margin: '0' }}>Threads Scraper</h3>
                            <h3 style={{ margin: '0', textAlign: 'right' }}>
                                Status: {data4 !== undefined && data4.status !=='server down' ? data4?.message : "Please Start Server"}
                                
                            </h3>
                        </div>
                        <ThreadsServiceStatus
                            data4={data4}
                            isLoading4={isLoading4}
                            refetch4={refetch4} />
                           

                    </div>
                </div>
            </div>
        </>


    );
};

export default Services_status;
