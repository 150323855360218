import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Checkbox from '@mui/material/Checkbox';
const ITEMS_PER_PAGE = 5; 

const MyTable = (props) => {
    const { selectedChannel, selectAll,handleClose,selectedIds, handleSelectId, handleSelectAll, handleGetSelectedChannelInfo }=props;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(selectedChannel.length / ITEMS_PER_PAGE);

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return selectedChannel.slice(startIndex, endIndex);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className="form_modal_scraping">
      <h1>Please Select Discord Channel</h1>
      <div style={{ border: '1px solid #dddddd' }}>
      <div style={{  overflow: 'auto' }}>
          <TableContainer component={Paper}>
            <Table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                maxWidth: '800px', 
                margin: '20px 0',
                fontSize: '18px', 
                padding: '16px', 
              }}
            >
              <TableHead>
                 <TableRow>
                  <TableCell sx={{ fontWeight: 'bold'}}>
                    Channel Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold'}}>
                    Channel Id
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold'}}>
                    Channel Server Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold'}}>
                    Channel Server Description
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold'}}>
                    Is Running
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold'}}>
                    select
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getCurrentPageData().map((channel) => (
                  <TableRow key={channel.channel_id}>
                    <TableCell>{channel.channel_name}</TableCell>
                    <TableCell>{channel.channel_id}</TableCell>
                    <TableCell>{channel.server_name}</TableCell>
                    <TableCell>{channel.server_description}</TableCell>
                    <TableCell>{channel.is_running ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      {/* <input
                      style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                        margin: '0',
                        padding: '0',
                      }}
                        type="checkbox"
                        checked={selectedIds.includes(channel.channel_id)}
                        onChange={() => handleSelectId(channel.channel_id)}
                      /> */}
                      <Checkbox
                        checked={selectedIds.includes(channel.channel_id)}
                        onChange={() => handleSelectId(channel.channel_id)}
                        sx={{
                          width: 24,
                          height: 24,
                          '& .MuiSvgIcon-root': {
                            fontSize: '1.5rem', 
                          },
                        
                        }}
                      />
                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="scraping_input_container">
        <Button
            color="primary"
            variant="contained"
            sx={{
              width: '25%',
              padding: '8px',
              fontSize: '1.5vh',
              fontWeight: '600',
              height: 'fit-content',
            }}
            onClick={handleSelectAll}
          >
            {selectAll ? 'Deselect All' : 'Select All'}
          </Button>
        </div>
      </div>

      <div className="scraping_input_container" style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
          color="error"
          variant="contained"
          sx={{
            width: '45%',
            fontSize: '1.5vh',
            fontWeight: '600',
            height: 'fit-content',
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="success"
          variant="contained"
          sx={{
            width: '45%',
            fontSize: '1.5vh',
            fontWeight: '600',
            height: 'fit-content',
          }}
          onClick={handleGetSelectedChannelInfo}
        >
          Submit
        </Button>
      </div>

      {/* Pagination */}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        size="large"
        sx={{ margin: '20px auto', display: 'flex', justifyContent: 'center' }}
      />
    </div>
  );
};

export default MyTable;
